// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#security-underlying-holding .underlying-holding-section {
  margin-top: 30px;
}
#security-underlying-holding .underlying-holding-section > .box-heading {
  padding: 12px;
}
#security-underlying-holding .header {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 10px;
}
#security-underlying-holding .header h2 {
  margin-bottom: 0;
  font-size: 2rem;
  line-height: normal;
}`, "",{"version":3,"sources":["webpack://./src/app/containers/security/details/underlying-holding/styles.scss"],"names":[],"mappings":"AAGE;EACE,gBAAA;AAFJ;AAII;EACE,aAAA;AAFN;AAME;EACE,aAAA;EACA,mBAAA;EACA,yBAAA;EACA,WAAA;EACA,mBAAA;AAJJ;AAMI;EACE,gBAAA;EACA,eAAA;EACA,mBAAA;AAJN","sourcesContent":["@import 'assets/scss/commons/variables';\n\n#security-underlying-holding {\n  .underlying-holding-section {\n    margin-top: 30px;\n\n    & > .box-heading {\n      padding: 12px;\n    }\n  }\n\n  .header {\n    display: flex;\n    flex-direction: row;\n    justify-content: flex-end;\n    width: 100%;\n    margin-bottom: 10px;\n\n    h2 {\n      margin-bottom: 0;\n      font-size: 2rem;\n      line-height: normal;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
