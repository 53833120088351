// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#report-options-toggleable > .card {
  background-color: transparent;
  border: 0;
  border-radius: 0;
}
#report-options-toggleable > .card > .card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  border: 1px solid #09acf8;
  margin-bottom: 0;
  cursor: pointer;
}
#report-options-toggleable > .card > .card-header .fs-icon-arrow-blue {
  display: flex;
}
#report-options-toggleable > .card > .card-header.collapsed .fs-icon-arrow-blue {
  transform: rotate(180deg);
}
#report-options-toggleable > .card > .card-body {
  margin-top: 15px;
  border-top: 1px solid #bcbfc4;
  border-bottom: 1px solid #bcbfc4;
}
#report-options-toggleable > .card > .card-body > .card-body__content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#report-options-toggleable > .card > .card-body > .card-body__content .content__bulk-actions {
  font-size: 0.85rem;
  text-align: right;
}
#report-options-toggleable > .card > .card-body > .card-body__content .content__bulk-actions > p {
  margin-bottom: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/containers/advisor/templates/report-options-toggleable/styles.scss"],"names":[],"mappings":"AAGE;EACE,6BAAA;EACA,SAAA;EACA,gBAAA;AAFJ;AAII;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,6BAAA;EACA,yBAAA;EACA,gBAAA;EACA,eAAA;AAFN;AAIM;EACE,aAAA;AAFR;AAKM;EACE,yBAAA;AAHR;AAOI;EACE,gBAAA;EACA,6BAAA;EACA,gCAAA;AALN;AAOM;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;AALR;AAOQ;EACE,kBAAA;EACA,iBAAA;AALV;AAOU;EACE,gBAAA;AALZ","sourcesContent":["@import 'assets/scss/commons/variables';\n\n#report-options-toggleable {\n  & > .card {\n    background-color: transparent;\n    border: 0;\n    border-radius: 0;\n\n    & > .card-header {\n      display: flex;\n      justify-content: space-between;\n      align-items: center;\n      background-color: transparent;\n      border: 1px solid $primary;\n      margin-bottom: 0;\n      cursor: pointer;\n\n      .fs-icon-arrow-blue {\n        display: flex;\n      }\n\n      &.collapsed .fs-icon-arrow-blue {\n        transform: rotate(180deg);\n      }\n    }\n\n    & > .card-body {\n      margin-top: 15px;\n      border-top: 1px solid $gray-light-2;\n      border-bottom: 1px solid $gray-light-2;\n\n      & > .card-body__content {\n        display: flex;\n        justify-content: space-between;\n        align-items: center;\n\n        .content__bulk-actions {\n          font-size: 0.85rem;\n          text-align: right;\n\n          & > p {\n            margin-bottom: 0;\n          }\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
