// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#data-to-account-transform-modal .modal-title {
  padding-top: 0;
  text-align: center;
  font-size: 1.75rem;
}
#data-to-account-transform-modal .modal-actions {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
}
#data-to-account-transform-modal .initial-step .modal-actions {
  flex-direction: column;
}
#data-to-account-transform-modal .initial-step .modal-actions > .btn {
  min-width: 275px;
}
#data-to-account-transform-modal .select-prospect-or-investor-step .modal-actions {
  justify-content: space-between;
  max-width: 315px;
  margin: 30px auto 0 auto;
}
#data-to-account-transform-modal .select-prospect-or-investor-step .modal-actions > .btn.btn-primary {
  min-width: 150px;
}
#data-to-account-transform-modal #create-new-client .table-white {
  padding: 0;
  border: 0;
}
#data-to-account-transform-modal .modal-dialog.modal-lg {
  max-width: 50rem;
}
#data-to-account-transform-modal .modal-dialog.modal-xl {
  max-width: 95%;
  width: 95%;
}
#data-to-account-transform-modal .investor-select {
  max-width: 315px;
}`, "",{"version":3,"sources":["webpack://./src/app/containers/advisor/ai-assistant/scan-iq/details/transform-data-to-create-account/styles.scss"],"names":[],"mappings":"AACE;EACE,cAAA;EACA,kBAAA;EACA,kBAAA;AAAJ;AAGE;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,SAAA;AADJ;AAKI;EACE,sBAAA;AAHN;AAKM;EACE,gBAAA;AAHR;AASI;EACE,8BAAA;EACA,gBAAA;EACA,wBAAA;AAPN;AASM;EACE,gBAAA;AAPR;AAYE;EACE,UAAA;EACA,SAAA;AAVJ;AAaE;EACE,gBAAA;AAXJ;AAcE;EACE,cAAA;EACA,UAAA;AAZJ;AAeE;EACE,gBAAA;AAbJ","sourcesContent":["#data-to-account-transform-modal {\n  .modal-title {\n    padding-top: 0;\n    text-align: center;\n    font-size: 1.75rem;\n  }\n\n  .modal-actions {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    gap: 15px;\n  }\n\n  .initial-step {\n    .modal-actions {\n      flex-direction: column;\n\n      & > .btn {\n        min-width: 275px;\n      }\n    }\n  }\n\n  .select-prospect-or-investor-step {\n    .modal-actions {\n      justify-content: space-between;\n      max-width: 315px;\n      margin: 30px auto 0 auto;\n\n      & > .btn.btn-primary {\n        min-width: 150px;\n      }\n    }\n  }\n\n  #create-new-client .table-white {\n    padding: 0;\n    border: 0;\n  }\n\n  .modal-dialog.modal-lg {\n    max-width: 50rem;\n  }\n\n  .modal-dialog.modal-xl {\n    max-width: 95%;\n    width: 95%;\n  }\n\n  .investor-select {\n    max-width: 315px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
