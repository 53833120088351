import React, { useCallback, useContext, useState, useEffect } from 'react';
import Disclosure from 'components/disclosure';
import Dropzone from 'components/dropzone';
import { reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import SpinnerLoader from 'components/performance-spinner';
import { toast } from 'react-toastify';
import _ from 'lodash';
import { AdvisorContext } from 'containers/advisor';
import MinusIcon from 'components/svg-icons/minus-icon';
import { connect } from 'react-redux';
import ScanLoadingCard from './loadingCard';
import './styles.scss';

const MAX_FILES = 1;
const DEFAULT_NOTIFICATION_TIME = 60000; //one minute

const ScanIQ = ({ fields, handleSubmit, tasks }) => {
  const { aiAssistantProvider, routerActions } = useContext(AdvisorContext);
  const [fileCSV, setFileCSV] = useState([]);
  const [loadingRequest, setLoadingRequest] = useState(false);
  const [fileId, setfileId] = useState('');
  const [taskID, setTaskId] = useState(null);
  const [extractionFunctions, setExtractionFunctions] = useState({});
  const [extractedFile, setExtractedFile] = useState(false);
  const [updatingFile, setUpdatingFile] = useState(false);

  const onRemoveFile = file => () => {
    const currentFiles = [...fileCSV];
    currentFiles.splice(currentFiles.indexOf(file), 1);
    setFileCSV(currentFiles);
  };

  const dropzoneFiles = fileCSV.map(file => (
    <div key={file.path} className="dropzone__file">
      <MinusIcon
        onClick={onRemoveFile(file)}
        style={{ height: 20, cursor: 'pointer' }}
        title="Remove file"
      />
      <span>{file.name}</span>
    </div>
  ));
  const hasFiles = !_.isEmpty(dropzoneFiles);

  const onDrop = useCallback(
    acceptedFiles => {
      if (acceptedFiles.length <= 0) return toast.error('Please add one file in the dropzone.');
      fields.file.onChange({ ...acceptedFiles });
      return setFileCSV([...acceptedFiles]);
    },
    [fileCSV]
  );

  const handleScan = () => {
    setLoadingRequest(true);
    setUpdatingFile(true);

    const formData = new FormData();
    formData.append('file', fileCSV[0]);
    aiAssistantProvider.scanFile(formData).then(response => {
      if (response.error || !response.data) {
        if (response.error?.errors?.file?.message === 'File has too many pages')
          toast.error(
            `The file has too many mages. The maximum allowed size is ${response.error.errors.file.max_page_count} pages.`
          );
        else if (response.error?.errors?.file?.message === 'File is too large')
          toast.error(
            `The file is too large. The maximum allowed size is ${response.error.errors.file.max_size}MB.`
          );
        else toast.error('Something went wrong uploading the file. Try again.');
        setFileCSV([]);
        return setLoadingRequest(false);
      }
      setfileId(response.data.id);
      setTaskId(response.data.task_id);
      setExtractionFunctions(response.data.extraction_functions);
      toast.success(
        <div className="loading-action-toast">
          <SpinnerLoader spinnerLoading />
          <span>
            This may take a few minutes. You can continue navigating, and we will notify you when
            it&apos;s ready.
          </span>
        </div>,
        {
          toastId: response.data.task_id,
          closeButton: true,
          autoClose: DEFAULT_NOTIFICATION_TIME,
          closeOnClick: false
        }
      );

      setFileCSV([]);
      return setUpdatingFile(false);
    });
  };

  useEffect(() => {
    if (
      taskID &&
      tasks[taskID] &&
      (tasks[taskID].scan_iq_extracted || tasks[taskID].scan_iq_update)
    )
      setExtractedFile(true);

    if (taskID && tasks[taskID] && tasks[taskID]?.scan_iq_completed) {
      setLoadingRequest(false);
      return routerActions.push(`/advisor/ai-assistant/scan-iq/${fileId}`);
    }

    if (taskID && tasks[taskID] && tasks[taskID]?.scan_iq_failed) {
      toast.error('Something went wrong uploading the file. Try again.');
      setFileCSV([]);
      return setLoadingRequest(false);
    }
  }, [tasks]);

  return (
    <form id="scan-iq" autoComplete="off" onSubmit={handleSubmit(handleScan)}>
      <h2>Scan IQ</h2>
      <button
        type="button"
        className="btn btn-transparent btn-go-back-tools"
        onClick={() => routerActions.push(`/advisor/ai-assistant/`)}
      >
        <i className="icon-up icon-arrow-rotate-Left" /> Return to tools
      </button>

      {loadingRequest ? (
        <ScanLoadingCard
          updatingFile={updatingFile}
          tasks={tasks[taskID]}
          extractedFile={extractedFile}
          extractedFunctions={extractionFunctions}
        />
      ) : (
        <div className="security-margin-wrapper">
          <h3>Select files to upload</h3>
          <div className="security-returns-btn-container space-btn">
            <div>
              <div className="dropzone" style={{ cursor: hasFiles ? 'auto' : 'pointer' }}>
                {hasFiles ? (
                  <div className="dropzone__preview">{dropzoneFiles}</div>
                ) : (
                  <Dropzone
                    onDrop={onDrop}
                    accept="application/pdf,image/jpeg,image/png"
                    maxFiles={MAX_FILES}
                  >
                    <div id="signature-dropzone-area" className="dropzone-area empty">
                      Click to browse
                      <small>Or drag & drop file here</small>
                    </div>
                  </Dropzone>
                )}
              </div>

              <button
                type="submit"
                className="btn btn-primary btn-scan-iq"
                disabled={_.isEmpty(fileCSV)}
              >
                Scan
              </button>
            </div>
          </div>
        </div>
      )}
      <Disclosure />
    </form>
  );
};

ScanIQ.propTypes = {
  fields: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  tasks: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  tasks: state.aiAssistant.tasks,

  fields: ['file'],
  initialValues: {
    file: ''
  }
});

const ScanIQWithForm = reduxForm({
  form: 'editSecurity',
  enableReinitialize: true
})(ScanIQ);

export default connect(mapStateToProps)(ScanIQWithForm);
