/* eslint-disable no-underscore-dangle */
import ModelEditGeneral, { modes } from 'components/advisor/common-components/general-popup';
import ModelsHeader from 'components/advisor/models/header';
import ModelMetaForm from 'components/advisor/models/model-meta';
import PositionsFormset, { mapPositions } from 'components/advisor/models/positions-formset';
import CSVWizard from 'components/utils/csv-wizard';
import StepsLine from 'components/utils/steps-line';
import { CLICK_COPY_MODEL_PORTFOLIO_BUTTON } from 'constants/actstream';
import { CustomerSupportEmailLink, CustomerSupportPhoneLink } from 'constants/contact';
import { BackendValidation } from 'hocs/backend-validation';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { toast } from 'react-toastify';
import { reduxForm } from 'redux-form';
import { validation } from 'utils/form';
import './styles.scss';

export { modes };

const validate = values => {
  const errors = {};
  errors.name = errors.name || validation.required(values.name);
  errors.positions = (values.positions || []).map(values => {
    const errors = {};
    if (values.deleted) return errors;

    errors.type = errors.type || validation.required(values.type);
    errors.subtype = errors.subtype || validation.required(values.subtype);
    errors.sector = errors.sector || validation.required(values.sector);
    errors.ticker = errors.ticker || validation.required(values.ticker);

    errors.value = errors.value || validation.required(values.value);
    errors.value = errors.value || validation.float(values.value);
    errors.value = errors.value || validation.floatPositive(values.value);
    errors.value =
      errors.value ||
      (parseFloat(values.value) < 0.1 && parseFloat(values.value) > 100.0
        ? 'Invalid percentage value'
        : undefined); // TODO: think of regular validator
    return errors;
  });

  const weightTotal = values.positions
    .filter(p => p.value && !p.deleted)
    .reduce((total, pos) => {
      const positionValue = typeof pos.value === 'string' ? parseFloat(pos.value) : pos.value;
      return total + positionValue;
    }, 0);

  if (typeof weightTotal !== 'undefined')
    if (parseFloat(weightTotal.toFixed(2)) !== 100) errors._total = 'Total weight should be 100%';
    else errors._test = null;

  return errors;
};

class ModelEdit extends ModelEditGeneral {
  constructor(props) {
    super(props);

    this.onFormChange = this.onFormChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.setCopy = this.setCopy.bind(this);
  }

  componentDidMount() {
    super.componentDidMount();
    const { wizardCSV } = this.props;
    if (wizardCSV) this.setState({ wizardCSV });
  }

  componentDidUpdate() {
    const { wizardCSV } = this.props;
    if (this.state.wizardCSV.show) this.setState({ csvPropsData: true });
    else if (wizardCSV && !this.state.csvPropsData) this.setState({ wizardCSV });
  }

  calculateOrUpdateRiskScore = id => {
    const { modelProvider } = this.context;
    const { onLoadingState } = this.props;

    if (onLoadingState) onLoadingState(true);

    // request risk analysis
    modelProvider.requireRiskAnalysis(id);

    // update prism and finish loading state for both actions
    modelProvider.updatePrism(id).finally(() => {
      if (onLoadingState) onLoadingState(false);
    });
  };

  onSubmit = values => {
    const { companyProvider, modelProvider, user } = this.context;
    const { calculateRisk, hideEditModel, meta, onCreate, onLimitReached } = this.props;
    const { copy } = this.state;

    const createModel = copy || !values.id;
    const positions = mapPositions(values.positions);
    const hasPermissionsToCreateStrategies = user?.advisor?.company?.allow_creating_strategies;

    const model = {
      name: values.name,
      positions,
      is_benchmark: values.is_benchmark ?? undefined,
      is_strategy: hasPermissionsToCreateStrategies ? values.is_strategy : undefined,
      team: values.team,
      type: values.type,
      visibility: values.visibility
    };
    const saveAction = createModel
      ? modelProvider.create(model)
      : modelProvider.update({ id: values.id, ...model });

    return saveAction
      .then(({ data, error }) => {
        if (error) {
          if (error.code === 402 && onLimitReached) onLimitReached();
          else if (error.errors) return Promise.reject(error.errors);
          else if (error.message) toast.error(error.message);
        } else if (data.id)
          if (createModel) {
            if (onCreate) onCreate(data.id);
            companyProvider.getLimits();

            if (calculateRisk) calculateRisk(data.id, data.name);
            else this.calculateOrUpdateRiskScore(data.id);

            modelProvider.list(meta?.params || {});
          } else this.calculateOrUpdateRiskScore(data.id);

        if (hideEditModel) hideEditModel();

        return data;
      })
      .catch(errors =>
        _.isEmpty(errors)
          ? Promise.reject(new Error('There was an error saving'))
          : Promise.reject(errors)
      );
  };

  setCopy = () => {
    const { values, fields } = this.props;
    const { actionProvider } = this.context;
    actionProvider.storeAction({ verb: CLICK_COPY_MODEL_PORTFOLIO_BUTTON });
    this.setState({ copy: true, formChanged: true });
    fields.name.onChange(`${values.name} copy(1)`);
    this.nameInput.focus();
  };

  render() {
    const { marketProvider, modelProvider } = this.context;

    const {
      fields,
      handleSubmit,
      submitting,
      error,
      invalid,
      onCSVInputChange,
      errors,
      marketStore,
      positions,
      isViewOnly
    } = this.props;

    const { copy, wizardCSV } = this.state;

    let { model } = this.props;
    model = model || {};

    const marketSecurities = marketStore.securities.search || [];

    // calculate total portfolio value
    const total = fields.positions.reduce((sum, position) => {
      try {
        sum += position.deleted.value ? 0 : Number(position.value.value) || 0;
      } catch (e) {
        console.warn(e); // eslint-disable-line no-console
      }
      return sum;
    }, 0);

    const copyButtonProps = model.id
      ? {
          onClick: this.setCopy,
          disabled: invalid || parseFloat(total.toFixed(3)) !== 100.0 || submitting || copy
        }
      : null;

    if (wizardCSV.show) {
      const steps = ['Assigning Fields', 'Processing', 'Result'];
      return (
        <div className="assign-rows">
          <div className="csv-header-actions">
            <span
              aria-label="Close"
              className="icon-remove"
              onKeyDown={() => {}}
              onClick={() => {
                this.onCSVModalClose();
                this.onFormChange();
              }}
              role="button"
              tabIndex="0"
            />
          </div>
          <StepsLine totalSteps={steps.length} currentStep={wizardCSV.step} titleSteps={steps} />
          <CSVWizard
            rows={wizardCSV.headerRows}
            fields={['ticker', 'weight']}
            currentStep={wizardCSV.step}
            positions={positions}
            processFields={this.processCSVFields}
            endWizard={this.endCSVWizard}
          />
        </div>
      );
    }

    const CSVInputChange =
      onCSVInputChange ||
      (e => {
        const input = e.target;
        const reader = new FileReader();
        reader.onload = this.onCSVLoaded;
        reader.readAsText(input.files[0]);
      });

    let modelHeaderLabel = '';
    if (isViewOnly) modelHeaderLabel = model.display_name;
    else {
      if (model.id) modelHeaderLabel = 'Edit';
      else modelHeaderLabel = 'Create';
      modelHeaderLabel += ' Model Portfolio';
    }

    return (
      <div>
        <ModelsHeader
          id="model-csv"
          modelId={model.id}
          title={modelHeaderLabel}
          onCSVInputChange={CSVInputChange}
          copyButtonProps={copyButtonProps}
          modelProvider={modelProvider}
          isViewOnly={isViewOnly}
        />

        <form
          onSubmit={handleSubmit(this.onSubmit)}
          autoComplete="off"
          onChange={this.onFormChange}
        >
          <PositionsFormset
            marketProvider={marketProvider}
            marketSecurities={marketSecurities}
            positions={fields.positions}
            totalError={errors._total}
            isViewOnly={isViewOnly}
          />

          {!isViewOnly && (
            <ModelMetaForm
              isBenchmark={fields.is_benchmark}
              isStrategy={fields.is_strategy}
              name={{ ...fields.name, refCb: this.saveNameInputRef }}
              onFormChange={this.onFormChange}
              owner={model?.advisor}
              team={fields.team}
              trackingParams={{ id: model?.id, name: model?.display_name, action: 'Editing' }}
              type={fields.type}
              visibility={fields.visibility}
            />
          )}

          {error && <p className="text-danger">{error}</p>}

          <div className="text-sm-center my-3">
            <div>
              <button
                className="btn btn-primary btn-lg btn-title"
                disabled={submitting}
                type="submit"
              >
                {model.id ? 'Save Model & Run Prism' : 'Create Model'}
              </button>
            </div>
          </div>
        </form>
        <div className="model-notification">
          We currently support: <b>Stocks, ETFs, Mutual Funds and Alternatives.</b> Our team is
          working on adding support for other security types. For specific questions, please contact{' '}
          <CustomerSupportEmailLink /> or call <CustomerSupportPhoneLink />.
        </div>
      </div>
    );
  }
}

ModelEdit.contextTypes = {
  actionProvider: PropTypes.object.isRequired,
  companyProvider: PropTypes.object.isRequired,
  marketProvider: PropTypes.object.isRequired,
  modelProvider: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
};

ModelEdit.defaultProps = {
  isViewOnly: false,
  meta: {},
  onLoadingState: () => {},
  wizardCSV: null
};

ModelEdit.propTypes = {
  error: PropTypes.string,
  errors: PropTypes.object.isRequired,
  fields: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  isViewOnly: PropTypes.bool,
  marketStore: PropTypes.object.isRequired,
  meta: PropTypes.object,
  onLoadingState: PropTypes.func,
  submitting: PropTypes.bool.isRequired,
  wizardCSV: PropTypes.object
};

export default reduxForm({
  form: 'editModel',
  fields: [
    'id', // hidden
    'name',
    'team',
    'type',
    'is_benchmark',
    'is_strategy',
    'visibility',
    '_total', // hidden, just to save wrong total value error
    'positions[].id', // hidden
    'positions[].security',
    'positions[].ticker_name',
    'positions[].type',
    'positions[].subtype',
    'positions[].sector',
    'positions[].ticker',
    'positions[].value',
    'positions[].prism_score_summary__overall',
    'positions[].deleted' // ie. "percentage value"
  ],
  validate
})(BackendValidation(ModelEdit));
