/* eslint-disable jsx-a11y/control-has-associated-label, no-underscore-dangle */
import ModelEditGeneral, { modes } from 'components/advisor/common-components/general-popup';
import NameModelForm from 'components/advisor/investors/name-model-form';
import ModelsHeader from 'components/advisor/models/header';
import PositionsFormset, { mapPositions } from 'components/advisor/models/positions-formset';
import CSVWizard from 'components/utils/csv-wizard';
import StepsLine from 'components/utils/steps-line';
import { ADDED_PROSPECT_ACCOUNT_MANUALLY } from 'constants/actstream';
import { CustomerSupportEmailLink, CustomerSupportPhoneLink } from 'constants/contact';
import { DEFAULT_SCOPE, SCAN_IQ_SCOPE } from 'containers/advisor/ai-assistant/constants';
import { BackendValidation } from 'hocs/backend-validation';
import PropTypes from 'prop-types';
import React from 'react';
import { toast } from 'react-toastify';
import { reduxForm } from 'redux-form';
import { validation } from 'utils/form';
import { trackAmplitudeEvent } from 'utils/tracking';
import './styles.scss';
import { DefaultToast, ScanIqToast } from './utils';

export { modes };

const validate = values => {
  const errors = {};
  errors.name = errors.name || validation.required(values.name);
  errors.positions = (values.positions || []).map(values => {
    const errors = {};
    if (values.deleted) return errors;

    errors.type = errors.type || validation.required(values.type);
    errors.subtype = errors.subtype || validation.required(values.subtype);
    errors.sector = errors.sector || validation.required(values.sector);
    errors.ticker = errors.ticker || validation.required(values.ticker);

    errors.value = errors.value || validation.required(values.value);
    errors.value = errors.value || validation.float(values.value);
    errors.value = errors.value || validation.floatPositive(values.value);
    return errors;
  });
  return errors;
};

class ModelEditInvestors extends ModelEditGeneral {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.state.defaultFields = ['ticker', 'amount'];
  }

  componentDidMount() {
    super.componentDidMount();
    const { wizardCSV } = this.props;
    if (wizardCSV) this.setState({ wizardCSV });
  }

  onSubmit = values => {
    const { accountProvider, actionProvider } = this.context;
    const { account, hideEditModel, investor, mode, onSuccess, reCalculatePrism, scope } =
      this.props;

    const createAccount = mode === modes.CREATE;
    const positions = mapPositions(values.positions);

    const saveAction = createAccount
      ? accountProvider.create({
          name: values.name,
          held_away: values.held_away,
          investor: investor.id,
          positions
        })
      : accountProvider.update({
          id: account.id,
          name: values.name,
          investor: investor.id,
          positions
        });

    return saveAction
      .then(({ data: account, error }) => {
        if (error && error.errors) return Promise.reject(error.errors);
        if (account.id) {
          toast.success(() =>
            scope === SCAN_IQ_SCOPE ? (
              <ScanIqToast account={account} />
            ) : (
              <DefaultToast account={account} creating={createAccount} />
            )
          );
          reCalculatePrism(account.id, createAccount && account.value > 0);

          if (mode === modes.CREATE && investor.is_prospect)
            actionProvider.slack({ verb: ADDED_PROSPECT_ACCOUNT_MANUALLY });

          onSuccess();

          if (hideEditModel) hideEditModel();

          trackAmplitudeEvent('account.created', { id: account?.id, name: account?.display_name });
        }

        return account;
      })
      .catch(errors => Promise.reject(new Error(errors)));
  };

  render() {
    const { accountProvider, authProvider, marketProvider, user } = this.context;
    const {
      account,
      error,
      errors,
      fields,
      handleSubmit,
      invalid,
      investor,
      marketStore,
      onCSVInputChange,
      positions,
      scope,
      submitting
    } = this.props;
    let { model } = this.props;

    const isComplianceOrAbove = authProvider.hasCompliancePermissionsOrAbove(user);

    const { copy, wizardCSV, defaultFields } = this.state;

    model = model || {};
    const marketSecurities = marketStore.securities.search || [];

    // calculate total portfolio value
    const total = fields.positions.reduce((sum, position) => {
      try {
        sum += position.deleted.value ? 0 : Number(position.value.value) || 0;
      } catch {} // eslint-disable-line no-empty
      return sum;
    }, 0);

    const copyButtonProps = model.id
      ? {
          onClick: this.setCopy,
          disabled: invalid || total.toFixed(3) !== 100.0 || submitting || copy
        }
      : null;

    if (wizardCSV.show) {
      const steps = ['Assigning Fields', 'Processing', 'Result'];
      return (
        <div className="assign-rows">
          <div className="csv-header-actions">
            <span
              className="icon-remove"
              onClick={() => this.onCSVModalClose()}
              role="button"
              tabIndex="0"
              onKeyDown={() => {}}
            />
          </div>
          <StepsLine totalSteps={steps.length} currentStep={wizardCSV.step} titleSteps={steps} />
          <CSVWizard
            currentStep={wizardCSV.step}
            endWizard={this.endCSVWizard}
            fields={defaultFields}
            isAccountSource
            positions={positions}
            processFields={this.processCSVFields}
            rows={wizardCSV.headerRows}
          />
        </div>
      );
    }

    return (
      <div id="investor-edit-modal">
        <ModelsHeader
          copyButtonProps={copyButtonProps}
          id="model-csv"
          modelId={model.id}
          modelProvider={accountProvider}
          onCSVInputChange={onCSVInputChange}
          prospectModal
          title={`${model.id ? 'Edit' : 'Create'} Account`}
        />

        <form onSubmit={handleSubmit(this.onSubmit)} autoComplete="off">
          <NameModelForm
            name={{ ...fields.name, refCb: this.saveNameInputRef }}
            value={account ? account.name : ''}
          />

          <PositionsFormset
            absoluteValues
            activeExclude
            isComplianceOrAbove={isComplianceOrAbove}
            marketProvider={marketProvider}
            marketSecurities={marketSecurities}
            positions={fields.positions}
            totalError={errors._total}
          />

          {error && <p className="text-danger">{error}</p>}

          <div className="text-sm-center mt-3">
            <div>
              <button
                className="btn btn-primary btn-lg btn-title"
                disabled={submitting}
                type="submit"
              >
                {model.id ? 'Save Account & Run PRISM' : 'Create Account & Run PRISM'}
              </button>
            </div>
            <button type="button" className="btn btn-link" data-dismiss="modal" aria-label="Close">
              {scope === DEFAULT_SCOPE
                ? `Back to ${investor.is_prospect ? 'Prospects' : 'Clients'}`
                : 'Cancel'}
            </button>
          </div>
        </form>
        <div className="model-notification">
          We currently support: <b>Stocks, ETFs, Mutual Funds and Alternatives.</b> Our team is
          working on adding support for other security types. For specific questions, please contact{' '}
          <CustomerSupportEmailLink /> or call <CustomerSupportPhoneLink />
        </div>
      </div>
    );
  }
}

ModelEditInvestors.contextTypes = {
  user: PropTypes.object.isRequired,
  authProvider: PropTypes.object.isRequired,
  ...ModelEditInvestors.contextTypes
};

ModelEditInvestors.propTypes = {
  onSuccess: PropTypes.func,
  scope: PropTypes.string
};

ModelEditInvestors.defaultProps = {
  onSuccess: () => {},
  scope: DEFAULT_SCOPE
};

export default reduxForm({
  form: 'editModel',
  fields: [
    'id', // hidden
    'name',
    'held_away',
    'type',
    '_total', // hidden, just to save wrong total value error
    'positions[].id', // hidden
    'positions[].security',
    'positions[].ticker_name',
    'positions[].type',
    'positions[].subtype',
    'positions[].sector',
    'positions[].ticker',
    'positions[].value',
    'positions[].prism_score_summary__overall',
    'positions[].deleted', // ie. "percentage value"
    'positions[].excluded'
  ],
  validate
})(BackendValidation(ModelEditInvestors));
