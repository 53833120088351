import SpinnerLoader from 'components/performance-spinner';
import React from 'react';
import { toast } from 'react-toastify';
import { Link } from 'react-router';
import {
  INVALID_POSITIONS,
  RISK_ANALYSIS_URL_REGEX,
  MODEL_PORTFOLIO_CALCULATING_RISK_MESAGGE,
  MODEL_PORTFOLIO_CREATION_ERROR_MESAGGE,
  MODEL_PORTFOLIO_CREATION_MESAGGE
} from './constants';

export const modelPortfolioCalculatingRiskToast = (
  message = MODEL_PORTFOLIO_CALCULATING_RISK_MESAGGE
) =>
  toast.info(
    () => (
      <div className="proposal-toast generating-div">
        <SpinnerLoader spinnerLoading />
        <div className="text-div">{message}</div>
      </div>
    ),
    { closeButton: true, autoClose: false, closeOnClick: false, timeout: 800 }
  );

export const createModelPortfolioCopy = async (portfolio, modelProvider, routerActions) =>
  modelProvider
    .create(portfolio)
    .then(async ({ data: model }) => {
      toast.info(MODEL_PORTFOLIO_CREATION_MESAGGE(model.name), { autoClose: 2000 });
      const riskNotification = modelPortfolioCalculatingRiskToast();
      routerActions.push(`/advisor/models/${model.id}/overview?copy=true`);
      await modelProvider.updatePrism(model.id).then(() => modelProvider.get(model.id));
      toast.update(riskNotification, { autoClose: 100 });
    })
    .catch(() => {
      toast.info(MODEL_PORTFOLIO_CREATION_ERROR_MESAGGE, { autoClose: 4000 });
    });

export const calculateRisk = (id, name, modelProvider, meta, isScanIQ = false) => {
  toast.info(
    <div>
      {isScanIQ
        ? `The model ${name} has been created.\nCalculating PRISM Score.`
        : 'Calculating PRISM Score.'}
    </div>,
    { autoClose: 6000 }
  );

  modelProvider
    .updatePrism(id)
    .then(response => {
      if (response.message === INVALID_POSITIONS)
        throw new Error('Please add prospective value to see the PRISM rating.');

      modelProvider.get(id).then(response => {
        if (!response.data?.prism_score_summary) return;
        const isModelPorfolioPage = window.location.pathname.match(RISK_ANALYSIS_URL_REGEX);
        if (meta) modelProvider.list(meta.params);
        toast.success(
          () => (
            <div className="toast-model-prism-calculated">
              {name
                ? `PRISM score for ${name} model was successfully calculated.`
                : 'PRISM score successfully calculated.'}
              <br />
              {!isModelPorfolioPage && (
                <Link
                  to={`/advisor/models/${id}/${isScanIQ ? 'overview' : 'risk-analysis'}`}
                  className="btn btn-primary btn-small"
                >
                  View
                </Link>
              )}
            </div>
          ),
          { autoClose: 6000 }
        );
      });
    })
    .catch((error = 'Error calculating the PRISM rating.') => {
      toast.error(error);
    });
};
