import LoadingPrismDataMessage from 'components/advisor/dashboard/loading-prism-data-message';
import RiskAnalysisPositions from 'components/advisor/risk-analysis/risk-analysis-target/risk-analysis-positions';
import SecurityTypeConcentration from 'components/advisor/risk-analysis/risk-analysis-target/security-type-concentration';
import {
  DEFAULT_PORTFOLIO_TOTAL_ASSETS,
  SECURITY_REGIONS,
  SECURITY_SECTORS,
  SECURITY_STYLES,
  SECURITY_UNDERLYING_MODEL
} from 'components/advisor/risk-analysis/securities/common/utils';
import ScrollCardsIndex from 'components/advisor/scroll-cards-index';
import Disclosure from 'components/disclosure';
import ScrollToTop from 'components/advisor/scroll-cards-index/scroll-to-top';
import { Modal, ModalBody, ModalHeader } from 'components/modal';
import SpinnerLoader from 'components/performance-spinner';
import { AdvisorContext } from 'containers/advisor';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { SECURITY_TARGET_TYPE } from '../../constants';
import UnderlyingHoldingsEditModal, { modes } from './edit';
import UnderlyingHoldingForm from './form';
import './styles.scss';
import { UnderlyingHoldingSection } from './utils';

const DELAY = 500;

const SecurityUnderlyingHolding = ({
  currentSecurityUnderlyingHoldings,
  marketStore,
  params,
  securitiesPositions,
  security,
  updateStep
}) => {
  const { customSecurityProvider } = useContext(AdvisorContext);

  const [loadingRequest, setLoadingRequest] = useState(false);
  const [preparing, setPreparing] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  const editModalRef = useRef(null);

  const securityId = params?.id;

  const hideEditModal = () => {
    setShowEditModal(false);
    setLoadingRequest(false);
    setPreparing(false);
    if (editModalRef.current) editModalRef.current.hide();
  };

  useEffect(() => {
    setLoadingRequest(true);
    if (security)
      customSecurityProvider
        .getUnderlyingHoldings(securityId)
        .finally(() => setLoadingRequest(false));
    return () => {
      customSecurityProvider.clearUnderlyingHoldings();
    };
  }, []);

  useEffect(() => {
    if (!_.isEmpty(currentSecurityUnderlyingHoldings)) setPreparing(true);
    setTimeout(() => {
      setShowEditModal(false);
      setPreparing(false);
    }, DELAY);
  }, [JSON.stringify(currentSecurityUnderlyingHoldings)]);

  const isLoading = () => {
    if (_.isEmpty(currentSecurityUnderlyingHoldings)) {
      setLoadingRequest(true);
      setTimeout(() => {
        setLoadingRequest(false);
      }, DELAY);
    }
  };

  if (loadingRequest) return <SpinnerLoader spinnerLoading />;

  const securityUnderlyingHoldingsWithValue = {
    ...currentSecurityUnderlyingHoldings,
    value: DEFAULT_PORTFOLIO_TOTAL_ASSETS
  };

  return (
    <div id="security-underlying-holding">
      <UnderlyingHoldingForm
        isCreatePage={!securityId}
        isLoading={isLoading}
        updateStep={updateStep}
      />

      {preparing && (
        <LoadingPrismDataMessage message="Preparing Underlying holdings Porfolio" inset />
      )}

      {!preparing && !_.isEmpty(currentSecurityUnderlyingHoldings) && (
        <>
          <ScrollCardsIndex
            portfolio={securityUnderlyingHoldingsWithValue}
            score={security.prism_overall}
          />

          <div className="header">
            <button
              className="btn btn-secondary create-button"
              onClick={() => {
                setShowEditModal(true);
                setPreparing(true);
              }}
              type="button"
            >
              Edit
            </button>
          </div>

          <div id="allocation">
            <RiskAnalysisPositions
              portfolio={currentSecurityUnderlyingHoldings}
              type={SECURITY_TARGET_TYPE}
            />
            <ScrollToTop />
          </div>

          <UnderlyingHoldingSection
            portfolio={securityUnderlyingHoldingsWithValue}
            title="Investment Style"
            type={SECURITY_STYLES}
          />

          <UnderlyingHoldingSection
            id="sector-exposure--header"
            portfolio={securityUnderlyingHoldingsWithValue}
            title="Sector Exposure"
            type={SECURITY_SECTORS}
          />

          <UnderlyingHoldingSection
            portfolio={securityUnderlyingHoldingsWithValue}
            title="Top 10 Holdings"
            type={SECURITY_UNDERLYING_MODEL}
          />

          <UnderlyingHoldingSection
            id="geographic-exposure--header"
            portfolio={securityUnderlyingHoldingsWithValue}
            title="Geographic Exposure"
            type={SECURITY_REGIONS}
          />

          <div className="underlying-holding-section box-container">
            <div className="box-heading">
              <span>Security Type Concentration</span>
            </div>
            <div className="box-content p-0">
              <SecurityTypeConcentration positions={currentSecurityUnderlyingHoldings.positions} />
            </div>
            <ScrollToTop />
          </div>
        </>
      )}

      <Modal
        id="modelEditModal"
        className="modal-lg"
        show={showEditModal}
        onHidden={hideEditModal}
        ref={editModalRef}
      >
        <ModalHeader />
        <ModalBody>
          <UnderlyingHoldingsEditModal
            hideEditModel={hideEditModal}
            marketStore={marketStore}
            mode={modes.UPDATE}
            model={currentSecurityUnderlyingHoldings}
            positions={securitiesPositions}
            security={security}
          />
        </ModalBody>
      </Modal>
      <Disclosure />
    </div>
  );
};

SecurityUnderlyingHolding.defaultProps = {
  securitiesPositions: [],
  updateStep: null
};

SecurityUnderlyingHolding.propTypes = {
  currentSecurityUnderlyingHoldings: PropTypes.object.isRequired,
  marketStore: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  securitiesPositions: PropTypes.array,
  security: PropTypes.object.isRequired,
  updateStep: PropTypes.func
};

const SecurityUnderlyingHoldingWithRouter = withRouter(SecurityUnderlyingHolding);

export default connect(state => ({
  currentSecurityUnderlyingHoldings: state.customSecurity.currentSecurityUnderlyingHoldings,
  marketStore: state.market,
  securitiesPositions: state.market.securities.positions,
  security: state.customSecurity.currentSecurity
}))(SecurityUnderlyingHoldingWithRouter);
