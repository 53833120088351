// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.investor-select {
  width: 100%;
  margin: auto;
  text-align: left;
}
.investor-select > div > div:nth-child(2) .search-icon {
  display: block;
  height: 16px;
  margin: 0 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/advisor/prospect-investor-async-select/styles.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,gBAAA;AACF;AAEI;EACE,cAAA;EACA,YAAA;EACA,cAAA;AAAN","sourcesContent":[".investor-select {\n  width: 100%;\n  margin: auto;\n  text-align: left;\n\n  & > div > div:nth-child(2) {\n    .search-icon {\n      display: block;\n      height: 16px;\n      margin: 0 10px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
