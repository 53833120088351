// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#documentation-note-introduction {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
}
#documentation-note-introduction .content {
  position: relative;
  border-radius: 4px;
  display: flex;
  padding: 37px 37px 37px 60px;
  border: 1px solid #c8c7c7;
  width: 100%;
  max-width: 765px;
  justify-content: space-between;
}
#documentation-note-introduction .documentation-note__title {
  position: absolute;
  top: -5%;
  left: 2%;
  background-color: white;
  padding-right: 7px;
}
#documentation-note-introduction .companies-datepicker {
  height: 38px;
  width: 100%;
  border: 1px solid #d3d6db;
}
#documentation-note-introduction .react-date-picker__wrapper {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  border: none;
}
#documentation-note-introduction .date-picker__wrapper {
  width: 100%;
  padding-right: 20px;
}
#documentation-note-introduction .radio-container {
  padding-left: 10px;
}
#documentation-note-introduction .radio__text {
  margin-left: 5px;
}
#documentation-note-introduction .date-picker__container {
  align-items: flex-start;
}
#documentation-note-introduction .source-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
#documentation-note-introduction .other-input__text {
  margin-left: 10px;
  display: inline-block;
  width: 200px;
}`, "",{"version":3,"sources":["webpack://./src/app/containers/risk-tolerance-questionnaire/documentation-note/styles.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,mBAAA;AADF;AAEE;EACE,kBAAA;EACA,kBAAA;EACA,aAAA;EACA,4BAAA;EACA,yBAAA;EACA,WAAA;EACA,gBAAA;EACA,8BAAA;AAAJ;AAGE;EACE,kBAAA;EACA,QAAA;EACA,QAAA;EACA,uBAAA;EACA,kBAAA;AADJ;AAIE;EACE,YAAA;EACA,WAAA;EACA,yBAAA;AAFJ;AAKE;EACE,aAAA;EACA,YAAA;EACA,cAAA;EACA,YAAA;AAHJ;AAME;EACE,WAAA;EACA,mBAAA;AAJJ;AAOE;EACE,kBAAA;AALJ;AAQE;EACE,gBAAA;AANJ;AASE;EACE,uBAAA;AAPJ;AAUE;EACE,aAAA;EACA,sBAAA;EACA,uBAAA;AARJ;AAWE;EACE,iBAAA;EACA,qBAAA;EACA,YAAA;AATJ","sourcesContent":["@import 'assets/scss/commons/variables';\n\n#documentation-note-introduction {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  margin-bottom: 30px;\n  .content {\n    position: relative;\n    border-radius: 4px;\n    display: flex;\n    padding: 37px 37px 37px 60px;\n    border: 1px solid #c8c7c7;\n    width: 100%;\n    max-width: 765px;\n    justify-content: space-between;\n  }\n\n  .documentation-note__title {\n    position: absolute;\n    top: -5%;\n    left: 2%;\n    background-color: white;\n    padding-right: 7px;\n  }\n\n  .companies-datepicker {\n    height: 38px;\n    width: 100%;\n    border: 1px solid $graphite-grayish-blue;\n  }\n\n  .react-date-picker__wrapper {\n    display: flex;\n    flex-grow: 1;\n    flex-shrink: 0;\n    border: none\n  }\n\n  .date-picker__wrapper {\n    width: 100%;\n    padding-right: 20px;\n  }\n\n  .radio-container{\n    padding-left: 10px;\n  }\n\n  .radio__text {\n    margin-left: 5px;\n  }\n\n  .date-picker__container {\n    align-items: flex-start;\n  }\n\n  .source-container {\n    display: flex;\n    flex-direction: column;\n    align-items: flex-start;\n  }\n\n  .other-input__text {\n    margin-left: 10px;\n    display: inline-block;\n    width: 200px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
