/* eslint-disable no-underscore-dangle */
import ModelEditGeneral, { modes } from 'components/advisor/common-components/general-popup';
import ModelsHeader from 'components/advisor/models/header';
import PositionsFormset, { mapPositions } from 'components/advisor/models/positions-formset';
import CSVWizard from 'components/utils/csv-wizard';
import StepsLine from 'components/utils/steps-line';
import { CLICK_COPY_MODEL_PORTFOLIO_BUTTON } from 'constants/actstream';
import { CustomerSupportEmailLink, CustomerSupportPhoneLink } from 'constants/contact';
import { POST_CREATE_STEP, UNDERLYING_HOLDING_WAS_SAVED } from 'containers/security/constants';
import { BackendValidation } from 'hocs/backend-validation';
import PropTypes from 'prop-types';
import React from 'react';
import { toast } from 'react-toastify';
import { reduxForm } from 'redux-form';
import { validation } from 'utils/form';
import './styles.scss';

export { modes };

const validate = values => {
  const errors = {};
  errors.positions = (values.positions || []).map(values => {
    const errors = {};
    if (values.deleted) return errors;

    errors.type = errors.type || validation.required(values.type);
    errors.subtype = errors.subtype || validation.required(values.subtype);
    errors.sector = errors.sector || validation.required(values.sector);
    errors.ticker = errors.ticker || validation.required(values.ticker);

    errors.value = errors.value || validation.required(values.value);
    errors.value = errors.value || validation.float(values.value);
    errors.value = errors.value || validation.floatPositive(values.value);
    errors.value =
      errors.value ||
      (parseFloat(values.value) < 0.1 && parseFloat(values.value) > 100.0
        ? 'Invalid percentage value'
        : undefined); // TODO: think of regular validator
    return errors;
  });

  const weightTotal = values.positions
    .filter(p => p.value && !p.deleted)
    .reduce((total, pos) => {
      const positionValue = typeof pos.value === 'string' ? parseFloat(pos.value) : pos.value;
      return total + positionValue;
    }, 0);

  if (typeof weightTotal !== 'undefined')
    if (parseFloat(weightTotal.toFixed(2)) !== 100) errors._total = 'Total weight should be 100%';
    else errors._test = null;

  return errors;
};

const SubmitButton = ({ text, disabled }) => (
  <div>
    <button type="submit" disabled={disabled} className="btn btn-primary btn-lg btn-title">
      {text}
    </button>
  </div>
);

class UnderlyingHoldingsEditModal extends ModelEditGeneral {
  constructor(props) {
    super(props);

    this.State = {
      loadingSubmit: false
    };

    this.onFormChange = this.onFormChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.setCopy = this.setCopy.bind(this);
  }

  calculateOrUpdateRiskScore = id => {
    const { modelProvider } = this.context;
    const { onLoadingState } = this.props;

    if (onLoadingState) onLoadingState(true);

    // request risk analysis
    modelProvider.requireRiskAnalysis(id);

    // update prism and finish loading state for both actions
    modelProvider.updatePrism(id).finally(() => {
      if (onLoadingState) onLoadingState(false);
    });
  };

  onSubmit = values => {
    const { customSecurityProvider } = this.context;
    const { hideEditModel, security, isCreatePage, updateStep } = this.props;

    const positions = mapPositions(values.positions);
    this.setState({ loadingSubmit: true });
    customSecurityProvider
      .createUnderlyingHoldings(security.id, positions)
      .then(response => {
        if (response.error)
          toast.error('An error occurred while saving the Underlying holding information.');
        else {
          toast.success('🎊 The security information was saved successfully.');
          customSecurityProvider.getUnderlyingHoldings(security.id).then(() => {
            if (hideEditModel) hideEditModel();
          });
          if (isCreatePage) updateStep(POST_CREATE_STEP, UNDERLYING_HOLDING_WAS_SAVED);
        }
        return response;
      })
      .finally(() => {
        this.setState({ loadingSubmit: false });
      });
  };

  setCopy = () => {
    const { values, fields } = this.props;
    const { actionProvider } = this.context;
    actionProvider.storeAction({ verb: CLICK_COPY_MODEL_PORTFOLIO_BUTTON });
    this.setState({ copy: true, formChanged: true });
    fields.name.onChange(`${values.name} copy(1)`);
    this.nameInput.focus();
  };

  componentDidUpdate() {
    const { wizardCSV } = this.props;
    if (this.state.wizardCSV.show) this.setState({ csvPropsData: true });
    else if (wizardCSV && !this.state.csvPropsData) this.setState({ wizardCSV });
  }

  render() {
    const { marketProvider, modelProvider } = this.context;
    const {
      fields,
      handleSubmit,
      submitting,
      error,
      invalid,
      onCSVInputChange,
      errors,
      marketStore,
      positions
    } = this.props;

    const { copy, wizardCSV, loadingSubmit } = this.state;

    let { model } = this.props;
    model = model || {};

    const marketSecurities = marketStore.securities.search || [];

    // calculate total portfolio value
    const total = fields.positions.reduce((sum, position) => {
      try {
        sum += position.deleted.value ? 0 : Number(position.value.value) || 0;
      } catch (e) {
        console.warn(e); // eslint-disable-line no-console
      }
      return sum;
    }, 0);

    const copyButtonProps = model.id
      ? {
          onClick: this.setCopy,
          disabled: invalid || parseFloat(total.toFixed(3)) !== 100.0 || submitting || copy
        }
      : null;

    if (wizardCSV.show) {
      const steps = ['Assigning Fields', 'Processing', 'Result'];
      return (
        <div className="assign-rows">
          <StepsLine totalSteps={steps.length} currentStep={wizardCSV.step} titleSteps={steps} />
          <CSVWizard
            rows={wizardCSV.headerRows}
            fields={['ticker', 'weight']}
            currentStep={wizardCSV.step}
            positions={positions}
            processFields={this.processCSVFields}
            endWizard={this.endCSVWizard}
          />
        </div>
      );
    }

    const CSVInputChange =
      onCSVInputChange ||
      (e => {
        const input = e.target;
        const reader = new FileReader();
        reader.onload = this.onCSVLoaded;
        if (input.files) reader.readAsText(input.files[0]);
      });

    return (
      <div>
        <ModelsHeader
          id="model-csv"
          modelId={model.id}
          title={`${model.id ? 'Edit' : 'Create'} Underlying Holdings`}
          onCSVInputChange={CSVInputChange}
          copyButtonProps={copyButtonProps}
          modelProvider={modelProvider}
        />

        <form onSubmit={handleSubmit(this.onSubmit)} autoComplete="off">
          {/* Form section with `positions` subform: */}
          <PositionsFormset
            marketProvider={marketProvider}
            marketSecurities={marketSecurities}
            positions={fields.positions}
            totalError={errors._total}
            withExcludePositionsFeature={false}
          />

          {error && <p className="text-danger">{error}</p>}

          <div className="text-sm-center my-3">
            {model.id && (
              <SubmitButton text="Edit Underlying holding" disabled={submitting || loadingSubmit} />
            )}
            {!model.id && (
              <SubmitButton
                text="Create Underlying holding"
                disabled={submitting || loadingSubmit}
              />
            )}
          </div>
        </form>
        <div className="model-notification">
          We currently support: <b>Stocks, ETFs, Mutual Funds and Alternatives.</b> Our team is
          working on adding support for other security types. For specific questions, please contact{' '}
          <CustomerSupportEmailLink /> or call <CustomerSupportPhoneLink />.
        </div>
      </div>
    );
  }
}

UnderlyingHoldingsEditModal.contextTypes = {
  actionProvider: PropTypes.object.isRequired,
  isCreatePage: PropTypes.bool,
  companyProvider: PropTypes.object.isRequired,
  customSecurityProvider: PropTypes.object.isRequired,
  marketProvider: PropTypes.object.isRequired,
  modelProvider: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
};

UnderlyingHoldingsEditModal.defaultProps = {
  isCreatePage: false,
  meta: {},
  security: null,
  onLoadingState: () => {},
  wizardCSV: null,
  updateStep: () => {}
};

UnderlyingHoldingsEditModal.propTypes = {
  error: PropTypes.string,
  errors: PropTypes.object.isRequired,
  fields: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  marketStore: PropTypes.object.isRequired,
  meta: PropTypes.object,
  onLoadingState: PropTypes.func,
  updateStep: PropTypes.func,
  security: PropTypes.object,
  submitting: PropTypes.bool.isRequired,
  wizardCSV: PropTypes.object
};

export default reduxForm({
  form: 'editModel',
  fields: [
    'id', // hidden
    'name',
    'team',
    'type',
    'is_benchmark',
    'is_strategy',
    'visibility',
    '_total', // hidden, just to save wrong total value error
    'positions[].id', // hidden
    'positions[].security',
    'positions[].ticker_name',
    'positions[].type',
    'positions[].subtype',
    'positions[].sector',
    'positions[].ticker',
    'positions[].value',
    'positions[].prism_score_summary__overall',
    'positions[].deleted'
  ],
  validate
})(BackendValidation(UnderlyingHoldingsEditModal));
