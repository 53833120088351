/* eslint-disable no-underscore-dangle */
import humps from 'humps';
import { INVESTMENT_STYLE_MAPPING } from '../investment-style/utils';
import { REGION_STYLE_MAPPING } from '../geographic-exposure/utils';
import { SECTOR_EXPOSURE_MAPPING } from '../sector-exposure/utils';

const EQUITY_TYPE = 2;

export const DEFAULT_PORTFOLIO_TOTAL_ASSETS = 100;
export const SECURITY_REGIONS = 'security_regions';
export const SECURITY_SECTORS = 'security_stock_sectors';
export const SECURITY_STYLES = 'security_styles';
export const SECURITY_UNDERLYING_MODEL = 'security_underlying_model';
export const PROPOSAL_SOURCE = 'proposal';

const SECURITY_SOURCE = {
  [SECURITY_REGIONS]: { id: 'region_id', name: 'region_name' },
  [SECURITY_SECTORS]: { id: 'sector_id', name: 'sector_name' },
  [SECURITY_STYLES]: { id: 'style_id', name: 'style_name' },
  [SECURITY_UNDERLYING_MODEL]: { id: 'id', name: 'ticker' }
};

const WEIGHT_RANGE_COLORS = [
  { min: 0, max: 0.1, background: '#DBF0FB' },
  { min: 0.1, max: 0.25, background: '#B3E5FC' },
  { min: 0.25, max: 0.5, background: '#81D4FA' },
  { min: 0.5, max: 1, background: '#4FC3F7' }
];

const withValidPosition =
  (type, breakdownCustomSecurities = false) =>
  position => {
    // the `SECURITY_UNDERLYING_MODEL` type is a special case because we always
    // need to consider the position itself or its underlying model
    if (type === SECURITY_UNDERLYING_MODEL) return true;

    // the function calls itself recursively to check the positions of the
    // `security_underlying_model` when applicable
    if (breakdownCustomSecurities && position.is_custom && position.security_underlying_model)
      return position.security_underlying_model.positions.some(withValidPosition(type));

    if (!position[type]) return false;
    const source = position[type];
    if (type === SECURITY_STYLES) return !!source.length && position.type === EQUITY_TYPE;
    return !!source.length;
  };

export const showSecurityDetailsSection = (portfolio, type) => {
  if (portfolio && (portfolio.value ?? 0) > 0 && portfolio.positions.some(withValidPosition(type)))
    return true;
  return false;
};

export const getSecurityDetails = (
  portfolio,
  type,
  totalAssets,
  breakdownCustomSecurities = false,
  portfolioWeight = 1
) => {
  const tickerNameMapping = {};
  const portfolioValue = portfolio.value ?? DEFAULT_PORTFOLIO_TOTAL_ASSETS;
  const weightedAccountValue = portfolioValue / totalAssets;
  const positions = portfolio.positions.filter(withValidPosition(type, breakdownCustomSecurities));
  const positionsDetails = positions.reduce((acc, position) => {
    const weightedPositionValue = position.value / portfolioValue;

    // The function calls itself recursively to get the security details of the
    // `security_underlying_model`. However, this does not apply when evaluating the
    // Top 10 Holdings, since by default, this should always be done on the parent position,
    // and not on the `security_underlying_model`.
    //
    // It's important to mention that the function cannot be called again because the
    // `breakdownCustomSecurities` parameter is set to `false`. Additionally, the `portfolioWeight`
    // parameter needs to be used to ensure that the weighting of the `security_underlying_model`
    // is correctly considered along with the rest of the positions of the parent portfolio.
    if (breakdownCustomSecurities && position.is_custom && type !== SECURITY_UNDERLYING_MODEL) {
      const underlyingModelPositions = getSecurityDetails(
        position.security_underlying_model,
        type,
        DEFAULT_PORTFOLIO_TOTAL_ASSETS,
        false,
        position.weight
      );
      return [...acc, ...underlyingModelPositions];
    }

    let source = position[type];

    // The `SECURITY_UNDERLYING_MODEL` type is a special case that takes into account
    // the positions of the underlying model (if applicable), or the position itself.
    // The value is set to `100` to represent 100% of the position.
    //
    // Two of the possible conditions must be met:
    // - The `breakdownCustomSecurities` parameter must be set to `true`, and the position must
    //   be a custom security.
    // - Any other position that has underlying model and is not a custom security.
    if (type === SECURITY_UNDERLYING_MODEL)
      if (
        source?.positions &&
        ((breakdownCustomSecurities && position.is_custom) || !position.is_custom)
      )
        source = source.positions;
      else source = [{ ...position, value: 100 }];

    return [
      ...acc,
      ...source.map(data => {
        const id = data[SECURITY_SOURCE[type].id];
        // `portfolioWeight` is finally used here to adjust the weight of each position accordingly
        const value =
          (data.value / 100) * weightedPositionValue * weightedAccountValue * portfolioWeight;

        let name = data[SECURITY_SOURCE[type].name];

        // the regular expression takes the first letter of each word in the string
        // and applies the `toUpperCase` function to it
        if (type === SECURITY_SECTORS) {
          name = humps.decamelize(name, { separator: ' ' });
          name = name.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
        }

        // stores the ticker's full name to be injected in the following step
        if (type === SECURITY_UNDERLYING_MODEL)
          if (!tickerNameMapping[name]) tickerNameMapping[name] = data.ticker_name;

        return {
          id,
          name,
          tickers: [{ ticker: position.ticker, ticker_name: position.ticker_name, value }],
          value
        };
      })
    ];
  }, []);

  return Object.values(
    positionsDetails.reduce((acc, entity) => {
      const entityId = type === SECURITY_UNDERLYING_MODEL ? entity.name : entity.id;
      const prevEntity = acc[entityId];

      // injects the ticker's full name to ensure it will always be the same
      if (type === SECURITY_UNDERLYING_MODEL) entity.name = tickerNameMapping[entity.name];

      if (prevEntity)
        return {
          ...acc,
          [entityId]: {
            ...prevEntity,
            tickers: [...prevEntity.tickers, ...entity.tickers],
            value: prevEntity.value + entity.value
          }
        };

      return { ...acc, [entityId]: entity };
    }, {})
  );
};

export const processSecurityDetails = (data, type, totalAssets, source) => {
  let styleMapping = {};
  if (type === SECURITY_STYLES) styleMapping = { ...INVESTMENT_STYLE_MAPPING };
  if (type === SECURITY_SECTORS) styleMapping = { ...SECTOR_EXPOSURE_MAPPING };
  if (type === SECURITY_REGIONS) styleMapping = { ...REGION_STYLE_MAPPING };

  // if `styleMapping` is missing order, set it alphabetically
  Object.keys(styleMapping).forEach(key => {
    if (!styleMapping[key].order) styleMapping[key].order = key;
  });

  const totalSecurities = Object.values(data).reduce(
    (acc, entity) => acc + entity.value * totalAssets,
    0
  );

  let chart = {};
  if (type === SECURITY_STYLES)
    chart = data.map(entity => ({
      background: styleMapping[entity.name]?.background,
      name: entity.name,
      percentage: entity.value,
      text: styleMapping[entity.name]?.text,
      value: entity.value * totalAssets
    }));
  if (type === SECURITY_SECTORS) {
    // sort by entity name
    data = data.sort((a, b) => b.name.localeCompare(a.name));
    if (source === PROPOSAL_SOURCE)
      chart = data.map(entity => ({
        color: styleMapping[entity.name]?.background,
        title: entity.name,
        value: parseFloat((entity.value * 100).toFixed(2))
      }));
    else
      chart = data.map(entity => ({
        color: styleMapping[entity.name]?.background,
        title: entity.name,
        value: parseFloat((((entity.value * totalAssets) / totalSecurities) * 100).toFixed(2))
      }));
  }
  if (type === SECURITY_REGIONS)
    if (source === PROPOSAL_SOURCE)
      chart = data.map(entity => ({
        color: styleMapping[entity.name]?.background,
        title: entity.name,
        value: parseFloat((entity.value * 100).toFixed(2))
      }));
    else
      chart = data.map(entity => ({
        color: styleMapping[entity.name].background,
        title: entity.name,
        value: parseFloat((((entity.value * totalAssets) / totalSecurities) * 100).toFixed(2))
      }));

  const summary = data.reduce((acc, entity) => {
    const fallbackStyleMapping = WEIGHT_RANGE_COLORS.find(range => {
      const weight = (entity.value * totalAssets) / totalSecurities;
      return weight >= range.min && weight <= range.max;
    });
    const tickers = entity.tickers.reduce(
      (acc, t) => ({
        ...acc,
        [t.ticker_name]: { __id: t.ticker_name, __value: t.value * totalAssets }
      }),
      {}
    );
    return {
      ...acc,
      [entity.name]: {
        __color: styleMapping[entity.name]?.background ?? fallbackStyleMapping.background,
        __icon: styleMapping[entity.name]?.src,
        __report_icon: styleMapping[entity.name]?.reportIcon,
        __id: entity.id,
        __order: styleMapping[entity.name]?.order || entity.value * -1,
        __value: entity.value * totalAssets,
        ...tickers
      }
    };
  }, {});

  return {
    data: { chart, summary: { ...summary, __value: totalSecurities } },
    total: totalSecurities
  };
};
