// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.new-tag {
  background: #70e0fb;
  border-radius: 5px;
  color: #055c88;
  font-size: 12px;
  margin: 0 5px;
  font-weight: bold;
  padding: 4px 10px;
  white-space: nowrap;
  max-height: 22px;
  display: inline;
}`, "",{"version":3,"sources":["webpack://./src/app/containers/advisor/new-tag/styles.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,kBAAA;EACA,cAAA;EACA,eAAA;EACA,aAAA;EACA,iBAAA;EACA,iBAAA;EACA,mBAAA;EACA,gBAAA;EACA,eAAA;AACF","sourcesContent":[".new-tag {\n  background: #70e0fb;\n  border-radius: 5px;\n  color: #055c88;\n  font-size: 12px;\n  margin: 0 5px;\n  font-weight: bold;\n  padding: 4px 10px;\n  white-space: nowrap;\n  max-height: 22px;\n  display: inline;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
