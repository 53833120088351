import Disclosure from 'components/disclosure';
import SpinnerLoader from 'components/performance-spinner';
import { AdvisorContext } from 'containers/advisor';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { reduxForm } from 'redux-form';
import { POSITIONS_BY_ACCOUNT } from '../../constants';
import ScanLoadingCard from '../loadingCard';
import DefaultTableSection from './default-table-section';
import './styles.scss';
import DataToAccountTransform from './transform-data-to-create-account';
import DataToModelTransform from './transform-data-to-create-model';

const ScanDetails = ({ params, tasks, marketStore }) => {
  const { aiAssistantProvider, routerActions } = useContext(AdvisorContext);
  const [loadingRequest, setLoadingRequest] = useState(true);
  const [fileStatusIsCompleted, setFileStatusIsCompleted] = useState(false);
  const [scanResponseFileData, setScanResponseFileData] = useState({});
  const [fileName, setFileName] = useState('');
  const [taskID, setTaskID] = useState('');
  const [extractionFunctions, setExtractionFunctions] = useState({});
  const [extractedFile, setExtractedFile] = useState(false);
  const [updatingFile, setUpdatingFile] = useState(false);

  const scanFileID = params.id;

  const handleGetScannedFile = () => {
    setLoadingRequest(true);
    aiAssistantProvider.getScannedFile(scanFileID).then(response => {
      if (response.error || !response.data || response.data.status === 'failed') {
        toast.error(
          'The document that you are trying to view does not exists or is not available.'
        );
        return routerActions.push(`/advisor/ai-assistant`);
      }

      setFileName(response.data.original_filename);
      setTaskID(response.data.task_id);
      setExtractionFunctions(response.data.extraction_functions);
      if (response.data.status === 'completed')
        return aiAssistantProvider.getResultFile(response.data.result_file).then(data => {
          toast.dismiss(response.data.task_id);
          setScanResponseFileData(data);
          setFileStatusIsCompleted(true);
          setLoadingRequest(false);
        });
      setFileStatusIsCompleted(false);

      return setLoadingRequest(false);
    });
  };

  useEffect(() => {
    setLoadingRequest(true);
    if (scanFileID) handleGetScannedFile();
  }, []);

  useEffect(() => {
    if (
      taskID &&
      tasks[taskID] &&
      (tasks[taskID].scan_iq_extracted || tasks[taskID].scan_iq_update)
    ) {
      setExtractedFile(true);
      setUpdatingFile(false);
    }

    if (taskID && tasks[taskID] && tasks[taskID]?.scan_iq_failed) {
      toast.error(
        'The file scan could not be completed. Please try again later or upload a different file.'
      );
      routerActions.push(`/advisor/ai-assistant`);
    }

    if (taskID && tasks[taskID] && tasks[taskID].scan_iq_completed) handleGetScannedFile();
  }, [tasks]);

  useEffect(() => {
    if (!loadingRequest) {
      const allEmptyOrNull = Object.values(scanResponseFileData).every(
        value => _.isEmpty(value) || value === null
      );

      if (allEmptyOrNull) {
        toast.error(
          'The document that you are trying to view does not exists or is not available.'
        );
        routerActions.push(`/advisor/ai-assistant`);
      }
    }
  }, [JSON.stringify(scanResponseFileData)]);

  const handleDynamicRendering = (key, data, extractedFileName) => (
    <>
      <h3 key={key}>{extractedFileName}</h3>
      <div className="card ai-assitant-default-card">
        <DefaultTableSection jsonData={data} />
      </div>
      {key === POSITIONS_BY_ACCOUNT && (
        <div className="ai-assistant-scan__btn-container">
          <DataToAccountTransform data={data} marketStore={marketStore} />
          <DataToModelTransform data={data} marketStore={marketStore} />
        </div>
      )}
    </>
  );

  const renderDynamicSections = () => {
    const sortedFunctions = extractionFunctions.sort((a, b) => a.order - b.order);

    return sortedFunctions.map(extractedFunction => {
      const key = extractedFunction.slug;
      if (scanResponseFileData[key])
        return handleDynamicRendering(key, scanResponseFileData[key], extractedFunction.name);
    });
  };

  if (loadingRequest) return <SpinnerLoader spinnerLoading />;

  return (
    <div id="scan-details">
      <div className="scan-title__container">
        <h2>Scan IQ</h2>
        <button
          className="btn btn-primary btn-redirect-scan-iq"
          type="button"
          onClick={() => routerActions.push(`/advisor/ai-assistant/scan-iq`)}
        >
          Scan another file
        </button>
      </div>
      <h3 className="scan-file-name">
        File: <span>{fileName}</span>
      </h3>
      {fileStatusIsCompleted ? (
        <div>{fileStatusIsCompleted && <div>{renderDynamicSections()}</div>}</div>
      ) : (
        <ScanLoadingCard
          tasks={tasks[taskID]}
          extractedFile={extractedFile}
          updatingFile={updatingFile}
          extractedFunctions={extractionFunctions}
        />
      )}
      <Disclosure />
    </div>
  );
};

ScanDetails.propTypes = {
  params: PropTypes.object.isRequired,
  tasks: PropTypes.object.isRequired,
  marketStore: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  tasks: state.aiAssistant.tasks,
  scanUpdate: state.aiAssistant.scanUpdate,
  marketStore: state.market,
  fields: ['file'],
  initialValues: {
    file: ''
  }
});

const ScanDetailsWithForm = reduxForm({
  form: 'editSecurity',
  enableReinitialize: true
})(ScanDetails);

export default connect(mapStateToProps)(ScanDetailsWithForm);
