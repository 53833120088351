import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

const formatKey = key => key.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase());

const validElement = element =>
  element !== null && element !== 0 && element !== 'None' && element !== '';
const validKey = ([key, value]) => validElement(key);
const validValue = ([key, value]) => validElement(value);

// Function to render values as a list
const renderList = value => {
  if (typeof value === 'object' && value !== null) {
    if (Array.isArray(value)) {
      // Filter out any items with values that don't pass `validElement`
      const filteredArray = value.filter(validElement);

      // If the array is empty or all items are filtered out, return null (don't render this section)
      if (filteredArray.length === 0) return null;

      // Check if the array contains objects with the same structure (like a table)
      const isTable = filteredArray.every(
        item => typeof item === 'object' && item !== null && !Array.isArray(item)
      );

      if (isTable) {
        // Extract headers from the first item in the array
        const headers = Object.keys(filteredArray[0]).filter(key =>
          filteredArray.some(item => validElement(item[key]))
        );

        return (
          <table className="scan-default__table defautl-table__list">
            <thead>
              <tr>
                {headers.map(key => (
                  <th key={key}>{formatKey(key)}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {filteredArray.map((item, rowIndex) => (
                <tr key={rowIndex}>
                  {headers.map(key => (
                    <td key={key}>{validElement(item[key]) ? item[key] : '-'}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        );
      }

      // Render as a list for non-table arrays
      return (
        <ul className="scan-iq__default-list">
          {filteredArray.map((item, index) => (
            <li key={index}>{renderList(item)}</li>
          ))}
        </ul>
      );
    }

    // Filter out any key-value pairs where value doesn't pass `validValue`
    const filteredEntries = Object.entries(value).filter(validValue);
    if (filteredEntries.length === 0) return null;

    return (
      <ul className="scan-iq__default-list">
        {filteredEntries.map(([key, val]) => (
          <li key={key}>
            <strong>{formatKey(key)}:</strong>{' '}
            {typeof val === 'object' ? renderList(val) : String(val)}
          </li>
        ))}
      </ul>
    );
  }
  return String(value);
};

// Function to render a table with header section for arrays with nested arrays
const renderTableWithHeader = value => (
  <div>
    {value.map((item, index) => {
      // Extract non-array key-value pairs for the header, filtering out invalid values
      const headerData = Object.entries(item).filter(
        ([key, val]) => !Array.isArray(val) && validElement(val)
      );

      // Extract the first nested array to render as a table
      const nestedArray = Object.entries(item).find(([key, val]) => Array.isArray(val));
      const nestedArrayData = nestedArray ? nestedArray[1] : [];

      return (
        <div key={index} className="table-with-header">
          <div className="header-section" style={{ padding: '10px', backgroundColor: '#f0f4f8' }}>
            {headerData.map(([key, val]) => (
              <div key={key} style={{ marginBottom: '5px' }}>
                <strong>{formatKey(key)}:</strong> {String(val)}
              </div>
            ))}
          </div>

          {nestedArrayData.length > 0 && (
            <table border="1" cellPadding="7" cellSpacing="0" className="scan-default__table">
              <thead>
                <tr>
                  {Object.keys(nestedArrayData[0])
                    .filter(key => nestedArrayData.some(item => validElement(item[key])))
                    .map(key => (
                      <th key={key}>{formatKey(key)}</th>
                    ))}
                </tr>
              </thead>
              <tbody>
                {nestedArrayData.map((nestedItem, nestedIndex) => (
                  <tr key={nestedIndex}>
                    {Object.entries(nestedItem)
                      .filter(validValue)
                      .map(([key, val], valIndex) => (
                        <td key={valIndex}>{val}</td>
                      ))}
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      );
    })}
  </div>
);

// Recursive function to handle nested objects and arrays as tables
const renderValue = value => {
  if (typeof value === 'object' && value !== null) {
    if (Array.isArray(value)) {
      const hasObjectWithNestedArray = value.some(
        item =>
          typeof item === 'object' &&
          item !== null &&
          Object.values(item).some(val => Array.isArray(val))
      );

      if (hasObjectWithNestedArray)
        // Use renderTableWithHeader for arrays with nested arrays
        return renderTableWithHeader(value);

      const headers = Object.keys(value[0] || {}).filter(key =>
        value.some(item => validElement(item[key]))
      );

      return (
        <table border="1" cellPadding="7" cellSpacing="0" className="scan-default__table">
          <thead>
            <tr>
              {headers.map(key => (
                <th key={key}>{formatKey(key)}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {value.map((item, index) => (
              <tr key={index}>
                {headers.map(key => (
                  <td key={key}>{validElement(item[key]) ? item[key] : '-'}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      );
    }

    return (
      <table border="1" cellPadding="5" cellSpacing="0" style={{ width: '100%' }}>
        <tbody>
          {Object.entries(value)
            .filter(validValue)
            .map(([key, val]) => (
              <tr key={key}>
                <td style={{ fontWeight: 'bold', backgroundColor: '#f1f1f1' }}>{formatKey(key)}</td>
                <td>{renderValue(val)}</td>
              </tr>
            ))}
        </tbody>
      </table>
    );
  }
  return String(value);
};

const DefaultTableSection = ({ jsonData }) => {
  if (Array.isArray(jsonData)) return renderValue(jsonData);
  return renderList(jsonData);
};

DefaultTableSection.propTypes = {
  jsonData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired
};

export default DefaultTableSection;
