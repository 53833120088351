// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#data-to-model-transform-modal .modal-dialog.modal-xl {
  max-width: 95%;
  width: 95%;
}`, "",{"version":3,"sources":["webpack://./src/app/containers/advisor/ai-assistant/scan-iq/details/transform-data-to-create-model/styles.scss"],"names":[],"mappings":"AACE;EACE,cAAA;EACA,UAAA;AAAJ","sourcesContent":["#data-to-model-transform-modal {\n  .modal-dialog.modal-xl {\n    max-width: 95%;\n    width: 95%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
