import ScrollCardsIndex from 'app/components/advisor/scroll-cards-index';
import ScrollToTop from 'app/components/advisor/scroll-cards-index/scroll-to-top';
import tooltipCloseButton from 'assets/img/tooltip-close-button.svg';
import {
  combinedPortfoliosSelector,
  totalAssetsSelector
} from 'components/advisor/investors/details/prism-investor/selectors';
import InvestorProfileBasic from 'components/advisor/investors/investor-profile-basic';
import { INVESTOR_TARGET_TYPE } from 'components/advisor/risk-analysis/constants';
import PositionsAnalysis from 'components/advisor/risk-analysis/risk-analysis-target/positions-analysis';
import { PORTFOLIO_POSITIONS_ANALYSIS_CHART_ID } from 'components/advisor/risk-analysis/risk-analysis-target/positions-analysis/constants';
import SecurityTypeConcentration from 'components/advisor/risk-analysis/risk-analysis-target/security-type-concentration';
import {
  SECURITY_REGIONS,
  SECURITY_SECTORS,
  SECURITY_STYLES,
  SECURITY_UNDERLYING_MODEL,
  showSecurityDetailsSection
} from 'components/advisor/risk-analysis/securities/common/utils';
import GeographicExposure from 'components/advisor/risk-analysis/securities/geographic-exposure';
import { GEOGRAPHIC_EXPOSURE_CHART_ID } from 'components/advisor/risk-analysis/securities/geographic-exposure/utils';
import InvestmentStyle from 'components/advisor/risk-analysis/securities/investment-style';
import SectorExposure from 'components/advisor/risk-analysis/securities/sector-exposure';
import { SECTOR_EXPOSURE_CHART_ID } from 'components/advisor/risk-analysis/securities/sector-exposure/utils';
import TopHoldings from 'components/advisor/risk-analysis/securities/top-holdings';
import AggregatedToleranceScoreBubble from 'components/advisor/utils/score-bubble/aggregated-tolerance';
import { Modal, ModalBody, ModalHeader } from 'components/modal';
import CardOverview from 'components/utils/card-overview';
import Link from 'components/utils/link';
import PrismRatingUpsideDownside from 'components/utils/prism-rating-upside-downside';
import tabs from 'constants/tabs';
import NewTag from 'containers/advisor/new-tag';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip';
import {
  getInvestorRtqSentTimestamp,
  getInvestorTargetScore,
  getRiskToleranceLatestUpdate,
  numToRiskScaleString
} from 'utils/utils';
import InvestorAccountsOverviewTable from './investor-accounts-overview-table';
import InvestorDetailStats from './investor-stats';
import './styles.scss';

class InvestorOverview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      basicProfileModal: false,
      investorAccounts: [],
      loading: true,
      tooltipManuallyClosed: false,
      householdSearch: '',
      houseHoldSelected: {},
      joinHouseHoldLoading: false
    };

    this.getInvestor = this.getInvestor.bind(this);
    this.onHouseholdSelected = this.onHouseholdSelected.bind(this);
    this.onHouseholdChange = _.debounce(this.onHouseholdChange, 750);
    this.handleJoinHouseHold = this.handleJoinHouseHold.bind(this);
    this.init = this.init.bind(this);
    this.getInvestorAccounts = this.getInvestorAccounts.bind(this);
    this.getData = this.getData.bind(this);
    this.riskToleranceTooltipRef = React.createRef();
  }

  componentDidMount() {
    this.init();
  }

  init() {
    Promise.all(this.getData()).then(response => {
      // There seems to be a race problem when getting the investor from
      // the Redux store, preventing the AggregatedToleranceScoreBubble
      // component from updating correctly and failing to show the proper score
      this.setState({
        investorAccounts: response[1].data,
        loading: false
      });
    });
  }

  showModal = () => this.setState({ basicProfileModal: true });

  closeModal = () => this.setState({ basicProfileModal: false });

  hideAssessRiskToleranceTooltip = () => {
    this.setState({ tooltipManuallyClosed: true });
    ReactTooltip.hide(this.riskToleranceTooltipRef.current);
  };

  getInvestor() {
    const { clientProvider } = this.context;
    const {
      params: { id }
    } = this.props;
    return clientProvider.get(id);
  }

  getInvestorAccounts() {
    const { clientProvider } = this.context;
    const {
      params: { id }
    } = this.props;
    return clientProvider.getAccounts(id);
  }

  getData() {
    return [this.getInvestor(), this.getInvestorAccounts()];
  }

  handleJoinHouseHold() {
    const { clientProvider } = this.context;
    const { investor } = this.props;
    const { houseHoldSelected } = this.state;
    const params = {
      id: houseHoldSelected.id
    };

    if (_.isEmpty(houseHoldSelected)) toast.error(() => <div>Choose a household to add</div>);
    else {
      this.setState({ joinHouseHoldLoading: true });
      clientProvider.linkHousehold(investor.id, params).then(() => {
        this.init();
      });
    }
  }

  onHouseholdSelected(suggestion) {
    this.setState({ householdSearch: suggestion.name, houseHoldSelected: suggestion });
  }

  onHouseholdChange = search => {
    const { householdProvider } = this.context;
    householdProvider.list({ search });
  };

  render() {
    const {
      basicProfileModal,
      investorAccounts,
      loading,
      tooltipManuallyClosed,
      householdSearch,
      joinHouseHoldLoading
    } = this.state;
    const { showTargetScoreWizard, user, clientProvider } = this.context;

    const {
      accounts,
      investor,
      selectedAccountIds,
      totalAccounts,
      totalValue,
      households,
      combinedPortfolios,
      totalAssets
    } = this.props;

    const prismScore = investor && investor.aggregated_prism_scores;
    const targetScore = getInvestorTargetScore(investor).data;
    const targetScoreLatestUpdate = getRiskToleranceLatestUpdate(investor, accounts);

    const clientType = investor.is_prospect ? 'prospects' : 'investors';
    const somePrismOverall = _.some(accounts, 'prism_score_summary');

    const investorRtqSentTimestamp = getInvestorRtqSentTimestamp(investor);

    const positions = accounts
      .filter(account => !account.excluded)
      .map(account => account.positions)
      .flat();

    // Check on every render if the tooltip should be hidden
    if (targetScore && !_.isEmpty(targetScore) && !investorRtqSentTimestamp)
      ReactTooltip.hide(this.riskToleranceTooltipRef.current);

    return (
      <div>
        {!loading && (
          <div className="investor-overview">
            {!_.isEmpty(positions) && !!totalAssets && (
              <ScrollCardsIndex
                score={prismScore && prismScore.overall}
                type={INVESTOR_TARGET_TYPE}
                portfolio={combinedPortfolios}
              />
            )}
            <InvestorDetailStats
              investor={investor}
              countGoals={investor.goals.length}
              countAccounts={totalAccounts}
              totalValue={totalValue}
              showModal={this.showModal}
              households={households}
              onHouseholdSelected={this.onHouseholdSelected}
              householdSearch={householdSearch}
              onHouseholdChange={this.onHouseholdChange}
              handleJoinHouseHold={this.handleJoinHouseHold}
              joinHouseHoldLoading={joinHouseHoldLoading}
            />
            {prismScore && prismScore.overall && (
              <div>
                <div className="prism-overview-header">
                  <span className="prism-header">
                    Portfolio Risk <span className="sub-text">Powered By</span> PRISM Rating &trade;
                  </span>
                  <span className="view-report">
                    <Link
                      to={`/advisor/${clientType}/${investor.id}/${tabs.PRISM}`}
                      className="btn btn-outline-secondary btn-prism"
                    >
                      View Report
                    </Link>
                  </span>
                </div>
                <div id="portfolio-risk">
                  <PrismRatingUpsideDownside
                    prismSummary={prismScore}
                    targetSummary={targetScore}
                    value={totalValue}
                    market={{
                      up: user.advisor.company.market_upside_performance,
                      down: user.advisor.company.market_downside_performance
                    }}
                  />
                </div>
              </div>
            )}
            <div className="card-deck">
              <CardOverview title="Risk Tolerance">
                <div>
                  <div className="info-div">
                    {targetScore && targetScore.overall ? (
                      <div className="tolerance-div">
                        {investor && (
                          <AggregatedToleranceScoreBubble
                            element={{ ...investor, accounts: investorAccounts }}
                            overview
                          />
                        )}
                        <span className="text">{numToRiskScaleString(targetScore.overall)}</span>
                      </div>
                    ) : (
                      <>
                        <span className="info-text tolerance">No Risk Tolerance Generated yet</span>
                        {investorRtqSentTimestamp && (
                          <span className="risk-tolerance-sent-timestamp">
                            (You sent a questionnaire on{' '}
                            {moment(investorRtqSentTimestamp).format('LL')})
                          </span>
                        )}
                      </>
                    )}
                  </div>
                  {targetScoreLatestUpdate && (
                    <div className="target-score-latest-update">{`(Last updated: ${moment
                      .utc(targetScoreLatestUpdate)
                      .fromNow()})`}</div>
                  )}
                  <div className="button-div">
                    {targetScore && _.isEmpty(targetScore) && investorRtqSentTimestamp ? (
                      <button
                        className="btn btn-outline-primary risk-card"
                        type="button"
                        onClick={() => showTargetScoreWizard()}
                      >
                        Send Reminder
                      </button>
                    ) : (
                      <>
                        <button
                          data-tip=""
                          data-for="assess-update-risk-tolerance-tooltip"
                          data-event="fake"
                          className="btn btn-primary risk-card"
                          type="button"
                          ref={this.riskToleranceTooltipRef}
                          onClick={() => showTargetScoreWizard()}
                        >
                          {targetScore && targetScore.overall
                            ? 'Update Risk Tolerance'
                            : 'Assess Risk Tolerance'}
                        </button>

                        <ReactTooltip
                          id="assess-update-risk-tolerance-tooltip"
                          effect="solid"
                          place="bottom"
                          clickable
                        >
                          <img
                            className="tooltip-close-button"
                            src={tooltipCloseButton}
                            alt="Close"
                            onClick={this.hideAssessRiskToleranceTooltip}
                          />
                          Find out risk tolerance by inviting the prospect/client to take the
                          questionnaire.{' '}
                          <span role="img" aria-label="happy-face">
                            😃
                          </span>
                        </ReactTooltip>

                        {targetScore &&
                          _.isEmpty(targetScore) &&
                          !investorRtqSentTimestamp &&
                          !tooltipManuallyClosed &&
                          ReactTooltip.show(this.riskToleranceTooltipRef.current)}
                      </>
                    )}
                  </div>
                </div>
              </CardOverview>
              <CardOverview title="Proposals">
                <div>
                  <div className="card-description">
                    <span className="info-text">
                      You can select a model portfolio and generate a proposal for this client.
                    </span>
                  </div>
                  <div className="button-div">
                    <Link
                      disabled={!somePrismOverall}
                      className="btn btn-primary"
                      to={`/advisor/${clientType}/${investor.id}/${tabs.PROPOSAL}`}
                    >
                      Generate Proposal
                    </Link>
                  </div>
                </div>
              </CardOverview>
              <CardOverview title="IPS">
                <div>
                  <div className="card-description">
                    <span className="info-text">
                      Risk Tolerance is required to generate an IPS.
                    </span>
                  </div>
                  <div className="button-div">
                    <Link
                      disabled={
                        !targetScore || !targetScore.overall || !prismScore || !prismScore.overall
                      }
                      className="btn btn-primary"
                      to={`/advisor/${clientType}/${investor.id}/${tabs.IPS}`}
                    >
                      Generate IPS
                    </Link>
                  </div>
                </div>
              </CardOverview>
            </div>
            <ScrollToTop />

            {!_.isEmpty(positions) && !!totalAssets && (
              <div className="client-overview-risk-factor-analysis" id="allocation">
                <h2>Assets Allocation</h2>
                <div className="overview-client">
                  <PositionsAnalysis
                    id={PORTFOLIO_POSITIONS_ANALYSIS_CHART_ID}
                    portfolio={combinedPortfolios}
                    className="fill-space"
                  />
                </div>
                <ScrollToTop />
              </div>
            )}

            {showSecurityDetailsSection(combinedPortfolios, SECURITY_STYLES) &&
              !_.isEmpty(positions) &&
              !!totalAssets && (
                <div className="client-overview-risk-factor-analysis box-position-analysis">
                  <div className="header">
                    <h2>
                      Investment Style <NewTag />
                    </h2>
                  </div>
                  <div className="investment-style-container">
                    <InvestmentStyle portfolio={combinedPortfolios} />
                  </div>
                  <ScrollToTop />
                </div>
              )}

            {showSecurityDetailsSection(combinedPortfolios, SECURITY_SECTORS) &&
              !_.isEmpty(positions) &&
              !!totalAssets && (
                <div className="client-overview-risk-factor-analysis box-position-analysis">
                  <div className="header" id="sector-exposure--header">
                    <h2>
                      Sector Exposure <NewTag />
                    </h2>
                  </div>
                  <div className="sector-exposure-container">
                    <SectorExposure id={SECTOR_EXPOSURE_CHART_ID} portfolio={combinedPortfolios} />
                  </div>
                  <ScrollToTop />
                </div>
              )}

            {showSecurityDetailsSection(combinedPortfolios, SECURITY_UNDERLYING_MODEL) &&
              !_.isEmpty(positions) &&
              !!totalAssets && (
                <div className="client-overview-risk-factor-analysis box-position-analysis">
                  <div className="header">
                    <h2>
                      Top 10 Holdings <NewTag />
                    </h2>
                  </div>
                  <div className="sector-exposure-container">
                    <TopHoldings portfolio={combinedPortfolios} />
                  </div>
                  <ScrollToTop />
                </div>
              )}

            {showSecurityDetailsSection(combinedPortfolios, SECURITY_REGIONS) &&
              !_.isEmpty(positions) &&
              !!totalAssets && (
                <div className="client-overview-risk-factor-analysis box-position-analysis">
                  <div className="header" id="geographic-exposure--header">
                    <h2>
                      Geographic Exposure <NewTag />
                    </h2>
                  </div>
                  <div className="geographic-exposure-container">
                    <GeographicExposure
                      id={GEOGRAPHIC_EXPOSURE_CHART_ID}
                      portfolio={combinedPortfolios}
                    />
                  </div>
                  <ScrollToTop />
                </div>
              )}

            {!_.isEmpty(positions) && !!totalAssets && (
              <div className="security-type-concentration">
                <h2>Security Type Concentration</h2>
                <SecurityTypeConcentration positions={positions} totalAssets={totalAssets} />
                <p>This chart only considers positions from non-excluded accounts.</p>
                <ScrollToTop />
              </div>
            )}

            {accounts && (
              <div className="investor-accounts__container">
                <InvestorAccountsOverviewTable
                  accounts={accounts}
                  getData={this.getData}
                  investor={investor}
                  selectedAccountIds={selectedAccountIds}
                />
                <div className="scroll-to-top__container">
                  <ScrollToTop />
                </div>
              </div>
            )}
          </div>
        )}
        <Modal
          id="basic-profile-modal"
          key={3}
          title="test"
          className="modal-lg modal-basic-profile"
          show={basicProfileModal}
          onShown={this.showModal}
          onHidden={this.closeModal}
        >
          <ModalHeader title={_.isEmpty(investor) ? '' : `${investor.full_name}'s Profile`} />
          <ModalBody>
            <InvestorProfileBasic
              investor={investor}
              investorProvider={clientProvider}
              closeModal={this.closeModal}
              getInvestor={this.getInvestor}
            />
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

InvestorOverview.propTypes = {
  accounts: PropTypes.array.isRequired,
  investor: PropTypes.object.isRequired,
  households: PropTypes.array.isRequired,
  params: PropTypes.object.isRequired,
  selectedAccountIds: PropTypes.array,
  totalAccounts: PropTypes.number.isRequired,
  totalAssets: PropTypes.number,
  totalValue: PropTypes.number.isRequired
};

InvestorOverview.defaultProps = {
  selectedAccountIds: [],
  totalAssets: null
};

InvestorOverview.contextTypes = {
  clientProvider: PropTypes.object.isRequired,
  showTargetScoreWizard: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  userProvider: PropTypes.object.isRequired,
  householdProvider: PropTypes.object.isRequired
};

function mergeProps(stateProps, dispatchProps, ownProps) {
  const {
    location: { pathname }
  } = ownProps;

  const { prospectAccounts, investorAccounts, investor, prospect } = stateProps;
  const isProspect = _.includes(pathname, 'advisor/prospects/');
  const accounts = isProspect ? prospectAccounts : investorAccounts;
  let totalAccounts = 0;
  let totalValue = 0;

  accounts.forEach(account => {
    if (account.excluded === false) {
      totalAccounts += 1;
      totalValue += account.value;
    }
  });

  return {
    ...stateProps,
    ...ownProps,
    ...dispatchProps,
    accounts,
    totalAccounts,
    totalValue,
    investor: isProspect ? prospect : investor
  };
}

export default connect(
  state => ({
    investor: state.investors.view,
    households: state.households.list,
    combinedPortfolios: combinedPortfoliosSelector(state),
    investorAccounts: state.investors.viewAccounts || [],
    prospect: state.prospects.view,
    prospectAccounts: state.prospects.viewAccounts || [],
    selectedAccountIds: state.accounts.selectedAccounts || [],
    totalAssets: totalAssetsSelector(state)
  }),
  null,
  mergeProps
)(InvestorOverview);
