import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import SpinnerLoader from 'components/performance-spinner';
import LoadingPrismDataMessage from 'components/advisor/dashboard/loading-prism-data-message';
import '../styles.scss';

const ScanLoadingCard = ({ updatingFile, extractedFile, tasks, extractedFunctions }) => {
  const [sortedFunctions, setSortedFunctions] = useState({});
  useEffect(() => {
    if (!_.isEmpty(extractedFunctions)) {
      const sortedExtractedFunctions = extractedFunctions.sort((a, b) => a.order - b.order);
      setSortedFunctions(sortedExtractedFunctions);
    }
  }, [JSON.stringify(extractedFunctions)]);

  return (
    <div id="scan-iq" className="card loading-card-message__container">
      <div className="loading-card__container">
        <div className="loading-item">
          <h5>Uploading file: </h5>
          {updatingFile ? (
            <SpinnerLoader spinnerLoading />
          ) : (
            <i className="icon-checkmark-circle ai-assistant--icon-checkmark" />
          )}
        </div>
        <div className="loading-item">
          <h5>Scanning text: </h5>
          {extractedFile ? (
            <i className="icon-checkmark-circle ai-assistant--icon-checkmark" />
          ) : (
            <SpinnerLoader spinnerLoading />
          )}
        </div>
        {extractedFile && !_.isEmpty(sortedFunctions) && (
          <div className="extract-file__container">
            {sortedFunctions.map(func => (
              <div key={func.name} className="loading-item">
                <h5>Extracting {func.name}: </h5>
                {tasks?.[func.name] === 'complete' ? (
                  <i className="icon-checkmark-circle ai-assistant--icon-checkmark" />
                ) : (
                  <div>
                    <SpinnerLoader spinnerLoading />
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
      <LoadingPrismDataMessage message="Processing file..." inset />
    </div>
  );
};

ScanLoadingCard.propTypes = {
  updatingFile: PropTypes.bool.isRequired,
  extractedFile: PropTypes.bool.isRequired,
  extractedFunctions: PropTypes.object.isRequired,
  tasks: PropTypes.object.isRequired
};

export default ScanLoadingCard;
