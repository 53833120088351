import ModelEdit, { modes } from 'components/advisor/models/edit';
import { calculateRisk } from 'components/advisor/models/utils';
import { Modal, ModalBody, ModalHeader } from 'components/modal';
import { PROCESSING_POSITIONS_STEP } from 'components/utils/csv-wizard/constants';
import { AdvisorContext } from 'containers/advisor';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import './styles.scss';

const DataToModelTransform = ({ data, marketStore }) => {
  const { modelProvider } = useContext(AdvisorContext);

  const [isShown, setIsShown] = useState(false);

  const [portfolio] = data;

  if (!portfolio || !portfolio?.positions?.length) return null;

  const hide = () => setIsShown(false);
  const show = () => setIsShown(true);

  const calculateRiskHandler = (id, name) => calculateRisk(id, name, modelProvider, {}, true);

  // the corresponding weight % will be calculated if the `percentage_of_account` attribute is not present
  const positions = portfolio.positions.map(position => ({
    ticker: position.ticker,
    weight:
      position.percentage_of_account ??
      (position.market_value / portfolio.total_account_value) * 100
  }));

  return (
    <>
      <button type="button" className="btn btn-secondary" onClick={show}>
        Create a Model Portfolio
      </button>

      <Modal className="modal-xl" id="data-to-model-transform-modal" onHidden={hide} show={isShown}>
        <ModalBody>
          <ModelEdit
            calculateRisk={calculateRiskHandler}
            hideEditModel={hide}
            initialValues={{ name: portfolio.account_name || '' }}
            marketStore={marketStore}
            mode={modes.CREATE}
            positions={marketStore.securities.positions}
            wizardCSV={{
              data: positions,
              headerRows: ['ticker', 'weight'],
              show: true,
              step: PROCESSING_POSITIONS_STEP
            }}
          />
        </ModalBody>
      </Modal>
    </>
  );
};

DataToModelTransform.propTypes = {
  data: PropTypes.array.isRequired,
  marketStore: PropTypes.object.isRequired
};

export default DataToModelTransform;
