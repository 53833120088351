/* eslint-disable no-underscore-dangle */
import { Image, Text, View } from '@react-pdf/renderer';
import { ProposalPropTypes } from 'components/advisor/proposal/body/sections/types';
import { ACCOUNT_PROPOSAL_TYPE } from 'components/advisor/proposal/constants';
import { SECURITY_REGIONS } from 'components/advisor/risk-analysis/securities/common/utils';
import { AdvisorContext } from 'containers/advisor';
import PropTypes from 'prop-types';
import React, { Fragment, useContext } from 'react';
import ExtraDataSection from 'reports/base/sections/extra';
import { formatPercentage, getTaxonomyLevelIterator } from 'utils/utils';
import { GEOGRAPHIC_EXPOSURE_CHART_ID } from 'components/advisor/risk-analysis/securities/geographic-exposure/utils';
import Row from '../../common/pdf/row';
import styles from '../../common/pdf/styles';
import { getDistributionData, getLevelPercent } from '../../common/utils';
import { PDF_IMAGE_STYLES } from '../options';

const GeographicExposureDistribution = ({
  benchmark,
  benchmarkName,
  breakdownCustomSecurities,
  proposal,
  recommended,
  recommendedName,
  start,
  startingValue,
  target,
  targetName
}) => {
  const {
    collapsedRegions,
    user: {
      advisor: {
        company: { expand_asset_details: expandAssetDetails }
      }
    }
  } = useContext(AdvisorContext);

  const isDraft = proposal?.target?.is_draft;

  const {
    distributionTable,
    target: { value: targetValue, total: targetProcessedTotal },
    recommended: { value: recommendedValue, total: recommendedProcessedTotal },
    benchmark: { value: benchmarkValue, total: benchmarkProcessedTotal }
  } = getDistributionData(
    target,
    recommended,
    benchmark,
    SECURITY_REGIONS,
    breakdownCustomSecurities
  );

  return (
    <View>
      <View>
        <Image src={proposal[GEOGRAPHIC_EXPOSURE_CHART_ID]} style={PDF_IMAGE_STYLES} />
      </View>
      <ExtraDataSection proposal={{ start, starting_value: startingValue }} />

      <View style={styles.table} wrap={false}>
        <View style={styles.row}>
          <View style={styles.cell} />
          {target && (
            <View style={[styles.cell, styles.targetHeaderCell]}>
              <Text style={styles.cellText}>{targetName || 'Current Portfolio'}</Text>
            </View>
          )}
          {recommended && (
            <View style={[styles.cell, styles.recommendedHeaderCell]}>
              <Text style={styles.cellText}>{recommendedName}</Text>
            </View>
          )}
          {benchmark && (
            <View style={[styles.cell, styles.benchmarkHeaderCell, { marginRight: 0 }]}>
              <Text style={styles.cellText}>{benchmarkName}</Text>
            </View>
          )}
        </View>
      </View>

      <View style={styles.table}>
        {getTaxonomyLevelIterator(distributionTable).map(([l1Key, l1], l1Idx) => (
          <Fragment key={`l1-${l1.__id ?? l1Idx}`}>
            <Row
              color={l1.__color}
              depth={1}
              isDraft={isDraft}
              label={l1Key}
              target={getLevelPercent(l1.__target_value, targetValue)}
              recommended={
                recommended ? getLevelPercent(l1.__recommended_value, recommendedValue) : null
              }
              benchmark={benchmark ? getLevelPercent(l1.__benchmark_value, benchmarkValue) : null}
            />

            {expandAssetDetails &&
              !collapsedRegions.includes(l1Key) &&
              getTaxonomyLevelIterator(l1).map(([l2Key, l2], l2Idx) => (
                <Fragment key={`l2-${l2.__id ?? `${l1Idx}-${l2Idx}`}`}>
                  <Row
                    depth={2}
                    isDraft={isDraft}
                    label={l2Key}
                    target={getLevelPercent(l2.__target_value, targetValue)}
                    recommended={
                      recommended ? getLevelPercent(l2.__recommended_value, recommendedValue) : null
                    }
                    benchmark={
                      benchmark ? getLevelPercent(l2.__benchmark_value, benchmarkValue) : null
                    }
                  />
                </Fragment>
              ))}
          </Fragment>
        ))}

        <View style={[styles.row, styles.rowLevel1]} wrap={false}>
          <View style={styles.cell}>
            <Text style={styles.cellTextLevel1}>Total Portfolio</Text>
          </View>
          <View style={[styles.cell, styles.recommendedCell]}>
            {isDraft && (
              <Text style={[styles.cellText, styles.cellTextLevel1, styles.recommendedCellText]} />
            )}
            <Text style={[styles.cellText, styles.cellTextLevel1]}>
              {formatPercentage(targetProcessedTotal / targetValue)}
            </Text>
          </View>
          {recommended && (
            <View style={[styles.cell, styles.recommendedCell]}>
              {!isDraft && (
                <Text
                  style={[styles.cellText, styles.cellTextLevel1, styles.recommendedCellText]}
                />
              )}
              <Text style={[styles.cellText, styles.cellTextLevel1, styles.recommendedCellText]}>
                {formatPercentage(
                  recommendedValue ? recommendedProcessedTotal / recommendedValue : 0
                )}
              </Text>
            </View>
          )}
          {benchmark && (
            <View style={[styles.cell, { marginRight: 0 }]}>
              <Text style={[styles.cellText, styles.cellTextLevel1]}>
                {formatPercentage(benchmarkValue ? benchmarkProcessedTotal / benchmarkValue : 0)}
              </Text>
            </View>
          )}
        </View>
      </View>
    </View>
  );
};

GeographicExposureDistribution.propTypes = {
  benchmark: PropTypes.object,
  benchmarkName: PropTypes.string,
  breakdownCustomSecurities: PropTypes.bool,
  proposal: PropTypes.shape(ProposalPropTypes),
  proposalType: PropTypes.string,
  recommended: PropTypes.object,
  recommendedName: PropTypes.string,
  start: PropTypes.string,
  startingValue: PropTypes.number,
  target: PropTypes.object,
  targetName: PropTypes.string
};

GeographicExposureDistribution.defaultProps = {
  benchmark: null,
  benchmarkName: '',
  breakdownCustomSecurities: true,
  proposal: null,
  proposalType: ACCOUNT_PROPOSAL_TYPE,
  recommended: null,
  recommendedName: '',
  start: null,
  startingValue: null,
  target: null,
  targetName: ''
};

export default GeographicExposureDistribution;
