import cn from 'classnames';
import ModelEdit, { modes } from 'components/advisor/models/edit';
import { createModelPortfolioCopy } from 'components/advisor/models/utils';
import LabelEdit from 'components/label-edit';
import { Modal, ModalBody, ModalHeader } from 'components/modal';
import QuestionMark from 'components/utils/question-mark';
import { CLICK_EDIT_MODEL_PORTFOLIO_BUTTON } from 'constants/actstream';
import { modelDetailTabs as tabs } from 'constants/tabs';
import { AdvisorContext } from 'containers/advisor';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { modelsWithPrismScoresInProgressSelector } from 'selectors/models';
import { MODEL_COPY_SUFFIX, STRATEGY_MESSAGE } from '../constants';
import { overviewLink, performanceLink, prismLink, strategyLink, accessControlLink } from './links';
import './styles.scss';

const TOOLTIP_TEXT = 'Model name';
const GETTING_PRISM_SCORE = 'Calculating PRISM Score...';
const DEFAULT_TAB = tabs.OVERVIEW;
const regularCopy = /\(copy\)/i;

const AdvisorModelsDetail = ({
  children,
  company,
  location,
  model,
  marketStore,
  params,
  prismScoresInProgress
}) => {
  const { accountProvider, actionProvider, modelProvider, routerActions, user, authProvider } =
    useContext(AdvisorContext);

  const [calculating, setCalculating] = useState(false);
  const [modelName, setModelName] = useState('');
  const [showEditModal, setShowEditModal] = useState(false);
  const [tab, setTab] = useState(DEFAULT_TAB);

  const editModalRef = useRef(null);
  const labelEditRef = useRef(null);

  const {
    securities: { positions }
  } = marketStore;

  const userIsComplianceOrAbove = authProvider.hasCompliancePermissionsOrAbove(user);
  const userIsOwner = model?.advisor === user?.advisor?.id;
  const allowAdvisorsSharingModels = company.allow_advisors_sharing_models;

  const isModelCopied = location?.query?.copy === 'true';
  const isStrategy = model?.is_strategy;
  const modelID = params?.id;
  const calculatingPrismScore = prismScoresInProgress.includes(Number(modelID));
  const reportBuilderEnabled = company.report_builder_enabled;
  const score = model?.prism_score_summary?.overall;
  const prismOverall = score ? score.toFixed(1) : null;

  const fetchModelData = () => {
    modelProvider.get(modelID);
  };

  const setCurrentTab = () => {
    const matchingTab = Object.values(tabs).find(value => location.pathname.includes(value));
    if (matchingTab && tab !== matchingTab) setTab(matchingTab);
  };

  const handleOnchangeModelName = e => {
    setModelName(e?.target?.value);
  };

  const handleOnSaveModelName = async () => {
    if (modelName) await modelProvider.updateModelName(model, { name: modelName });
  };

  useEffect(() => setModelName(model?.name), [model?.name]);

  useEffect(() => {
    fetchModelData();
    setCurrentTab();

    return () => {
      modelProvider.clearRiskAnalysis();
      modelProvider.clearModel();
      accountProvider.clearAccount();
    };
  }, []);

  useEffect(() => {
    setCurrentTab();
  }, [location.pathname]);

  useEffect(() => {
    if (
      labelEditRef.current &&
      isModelCopied &&
      regularCopy.test(model?.name) &&
      !calculatingPrismScore
    )
      labelEditRef?.current?.edit();
  }, [isModelCopied, labelEditRef, model?.name, calculatingPrismScore]);

  const showEditModalHandler = () => {
    actionProvider.storeAction({ verb: CLICK_EDIT_MODEL_PORTFOLIO_BUTTON });
    setShowEditModal(true);
  };

  const hideEditModal = () => {
    setShowEditModal(false);

    if (editModalRef.current) editModalRef.current.hide();
  };

  const toggleCalculatingScore = value => {
    setCalculating(value);
    setShowEditModal(false);
  };

  const handleMakeModelCopy = async () => {
    const sourceModel = model;

    const newModel = {
      name: `${sourceModel.name} ${MODEL_COPY_SUFFIX}`,
      positions: sourceModel.positions,
      type: sourceModel.type
    };

    return createModelPortfolioCopy(newModel, modelProvider, routerActions);
  };

  const isSubscribed = model?.subscription_status === 'accepted';
  const canEditModel = !isSubscribed && (userIsOwner || userIsComplianceOrAbove) && model?.name;
  const isViewOnly = !canEditModel;

  return (
    <div className="model-details-view">
      <div className="model-details-view__holder">
        <div className="model-details-view__model-name">
          <LabelEdit
            ref={labelEditRef}
            label={model?.name}
            inputValue={modelName}
            disable={calculatingPrismScore}
            onSave={handleOnSaveModelName}
            inputOnChange={handleOnchangeModelName}
            toolTipText={calculatingPrismScore ? GETTING_PRISM_SCORE : TOOLTIP_TEXT}
          />
          <div className="model-details-view__buttons">
            <button
              type="button"
              style={{ marginRight: 8 }}
              onClick={showEditModalHandler}
              className="btn btn-secondary"
            >
              {isViewOnly ? 'View Positions' : 'Edit'}
            </button>
            {model?.name && (
              <button
                type="button"
                style={{ marginRight: 8 }}
                onClick={handleMakeModelCopy}
                className="btn btn-outline-secondary font-weight-normal"
              >
                Make a copy
              </button>
            )}
          </div>
        </div>
        {isStrategy && (
          <div className="model-details-view__strategy-info">
            <span className="model-details-view__strategy-info-label">Strategy </span>
            <QuestionMark tooltipMessage={STRATEGY_MESSAGE} />
          </div>
        )}
        <ul className="model-details-view__tabs tabs">
          <li className={cn({ active: tab === tabs.OVERVIEW })}>{overviewLink({ modelID })}</li>
          <li className={cn({ active: tab === tabs.PRISM })}>
            {prismLink({ isStrategy, prismOverall, modelID })}
          </li>
          <li className={cn({ active: tab === tabs.PERFORMANCE })}>
            {performanceLink({ reportBuilderEnabled, modelID })}
          </li>
          <li className={cn({ active: tab === tabs.STRATEGY })}>{strategyLink({ modelID })}</li>
          {((userIsOwner && allowAdvisorsSharingModels) || userIsComplianceOrAbove) && (
            <li className={cn({ active: tab === tabs.ACCESS_CONTROL })}>
              {accessControlLink({ modelID })}
            </li>
          )}
        </ul>
        {React.cloneElement(children, { showEditModal, calculating })}{' '}
        {/* This function (React.cloneElement) is used to clone the children and add two news props (showEditModal and calculating) */}
      </div>
      <Modal
        id="modelEditModal"
        className="modal-lg"
        show={showEditModal}
        onHidden={hideEditModal}
        ref={editModalRef}
      >
        <ModalBody>
          <ModelEdit
            hideEditModal={hideEditModal}
            marketStore={marketStore}
            mode={modes.UPDATE}
            model={model}
            onLoadingState={toggleCalculatingScore}
            positions={positions}
            isViewOnly={isViewOnly}
          />
        </ModalBody>
      </Modal>
    </div>
  );
};

AdvisorModelsDetail.propTypes = {
  children: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  model: PropTypes.object,
  marketStore: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  prismScoresInProgress: PropTypes.array.isRequired
};

AdvisorModelsDetail.defaultProps = {
  model: null
};

AdvisorModelsDetail.contextTypes = {
  accountProvider: PropTypes.object.isRequired,
  modelProvider: PropTypes.object.isRequired
};

const AdvisorModelsDetailWithRouter = withRouter(AdvisorModelsDetail);

export default connect(state => ({
  company: state.auth.user.advisor.company,
  model: state.models.view,
  marketStore: state.market,
  prismScoresInProgress: modelsWithPrismScoresInProgressSelector(state)
}))(AdvisorModelsDetailWithRouter);
