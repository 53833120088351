import CreateAccountModal from 'components/advisor/investors/create/index';
import InvestorDetailsHeader from 'components/advisor/investors/details/header';
import InvestorDetailsView from 'components/advisor/investors/details/view';
import AccountEdit, { modes } from 'components/advisor/investors/edit/index';
import HeldAwayAccountsModal from 'components/advisor/investors/held-away-account-modal/index';
import InvestorProfileBasic from 'components/advisor/investors/investor-profile-basic';
import Disclosure from 'components/disclosure';
import { Modal, ModalBody, ModalHeader } from 'components/modal';
import SpinnerLoader from 'components/performance-spinner';
import { DEFINING_FIELDS_STEP } from 'components/utils/csv-wizard/constants';
import _ from 'lodash';
import Papa from 'papaparse';
import PropTypes from 'prop-types';
import InvestorProvider from 'providers/investor';
import ProspectProvider from 'providers/prospects';
import React from 'react';
import { connect } from 'react-redux';
import { routerActions } from 'react-router-redux';
import { toast } from 'react-toastify';
import { bindActionCreators } from 'redux';
import { LOAD_CSV_CONFIG, normalizeCsv } from 'utils/papa_parse_config';
import { triggerAccountPrismScore } from 'utils/prism';
import { getPortfolioForUpdate } from 'utils/utils';
import GeneralDetails from '../common-containers/common-detail-page';
import './styles.scss';

class InvestorsAccount extends GeneralDetails {
  constructor(props, context) {
    super(props, context);

    this.state = {
      createAccountModal: { show: false, investor: null, accountDefault: null },
      heldAway: false,
      heldAwayAccountModal: { show: false, investor: null },
      portfolioModal: { show: false, mode: modes.UPDATE },
      wizardCSV: null,
      investorLoaded: false
    };

    this.toggleCreateAccountModal = this.toggleCreateAccountModal.bind(this);
    this.showPortfolioModal = this.showPortfolioModal.bind(this);
    this.getPrismScore = this.getPrismScore.bind(this);
    this.onQuestionSave = this.onQuestionSave.bind(this);
    this.toggleHeldAwayAccountModal = this.toggleHeldAwayAccountModal.bind(this);
    this.heldAwaySubmit = this.heldAwaySubmit.bind(this);
    this.whichModal = this.whichModal.bind(this);
    this.toggleEditAccountModal = this.toggleEditAccountModal.bind(this);
    this.excludeAccount = this.excludeAccount.bind(this);
    this.fetchInvestorData = this.fetchInvestorData.bind(this);
  }

  componentDidMount() {
    this.fetchInvestorData();
  }

  componentDidUpdate(prevProps) {
    const {
      params: { id: oldInvestorId }
    } = prevProps;
    const {
      params: { id: newInvestorId }
    } = this.props;

    if (oldInvestorId !== newInvestorId) this.fetchInvestorData();
  }

  componentWillUnmount() {
    const { setupTargetScoreWizard } = this.context;
    setupTargetScoreWizard({});
    window.removeEventListener('drag', this.dragScrollHandler, false);
  }

  updatePage = () => {
    const {
      investor,
      route: { isProspect }
    } = this.props;
    const { investorProvider, prospectProvider } = this.context;
    const provider = isProspect ? prospectProvider : investorProvider;
    provider.get(investor.id).then(() => provider.getAccounts(investor.id));
  };

  fetchInvestorData() {
    const {
      provider,
      params: { id }
    } = this.props;
    const { investorGoalsProvider, setupTargetScoreWizard } = this.context;

    Promise.all([
      provider.get(id),
      provider.getAccounts(id),
      investorGoalsProvider.list({ investor: id })
    ]).then(([{ data: investor }]) => {
      setupTargetScoreWizard({ investors: [investor], refresh: this.updatePage });
      this.setState({ investorLoaded: true });
    });
  }

  onQuestionSave() {
    const {
      provider,
      params: { id },
      ordering
    } = this.props;

    const accountsParams = {};
    if (ordering) accountsParams.ordering = ordering;

    provider.getAccounts(id, accountsParams);
  }

  toggleCreateAccountModal(show = false) {
    this.setState({ createAccountModal: { show } });
  }

  toggleHeldAwayAccountModal(show = false) {
    this.setState({ heldAwayAccountModal: { show } });
  }

  whichModal() {
    const { investor } = this.props;
    if (investor && investor.is_prospect) this.toggleCreateAccountModal(true);
    else this.toggleHeldAwayAccountModal(true);
  }

  uploadCSVOption(e) {
    this.toggleCreateAccountModal();
    this.registerCSVLoadedCallback(this.uploadCSVCallback);
    this.onCSVInputChange(e);
  }

  uploadCSVCallback = e => {
    const { errorsProvider } = this.context;
    const result = normalizeCsv(e.target.result);
    const { data, errors } = Papa.parse(result, LOAD_CSV_CONFIG);
    if (data.length)
      this.setState({
        wizardCSV: {
          data,
          headerRows: Object.keys(data[0]),
          show: true,
          step: DEFINING_FIELDS_STEP
        }
      });

    if (errors && errors.length) errors.forEach(e => errorsProvider.registerError(e.message));
    this.showPortfolioModal(true, modes.CREATE);
  };

  showPortfolioModal(show = true, defaultAccount = {}, mode = modes.CREATE) {
    this.setState({ portfolioModal: { show, mode, account: defaultAccount } });
    if (!show) this.setState({ wizardCSV: null });
  }

  // eslint-disable-next-line default-param-last
  getPrismScore(accountId, reCalculate = false, event) {
    const {
      params: { id: investorId },
      provider
    } = this.props;
    const { accountProvider } = this.context;

    const updateStorePromises = () => [provider.get(investorId), provider.getAccounts(investorId)];

    triggerAccountPrismScore(accountId, accountProvider, updateStorePromises, reCalculate, event);
  }

  includePrismAccountsOnly() {
    const { investorAccounts } = this.props;
    const accounts = investorAccounts.filter(a => !a.excluded);
    return accounts;
  }

  getDate = date => new Date(date).toDateString();

  heldAwaySubmit = heldAway => {
    this.setState({
      createAccountModal: { show: true },
      heldAway,
      heldAwayAccountModal: { show: false }
    });
  };

  toggleEditAccountModal(account) {
    if (account)
      return this.setState({
        portfolioModal: {
          account,
          investor: account.investor,
          mode: modes.UPDATE,
          model: getPortfolioForUpdate(account),
          show: true
        }
      });

    return this.setState({ portfolioModal: { show: false } });
  }

  hideEditAccountModal() {
    this.setState({ editAccountModal: { show: false } });
    this.editAccountModal.hide();
  }

  excludeAccount(accountId, value) {
    const {
      provider,
      params: { id },
      ordering
    } = this.props;

    const accountsParams = {};
    if (ordering) accountsParams.ordering = ordering;

    const { accountProvider } = this.context;
    accountProvider
      .updatePatch(accountId, {
        excluded: value
      })
      .then(() => {
        provider.get(id);
        provider.getAccounts(id, accountsParams).then(() => {
          if (value)
            toast.success(() => (
              <div>
                The account has been excluded and will no longer be a part of the aggregated asset
                value and PRISM rating.
              </div>
            ));
          else
            toast.success(() => (
              <div>
                The account has been added and will be a part of the aggregated asset value and
                PRISM rating.
              </div>
            ));
        });
      });
  }

  getChildContext() {
    return {
      addAccount: this.whichModal,
      toggleEditAccountModal: this.toggleEditAccountModal
    };
  }

  render() {
    const {
      historical,
      investor,
      accounts,
      accountsWithoutGoal,
      investorAccountsMeta: { ordering },
      provider,
      routerActions,
      marketStore,
      user: currentUser,
      marketStore: {
        securities: { positions }
      },
      children
    } = this.props;

    const {
      createAccountModal,
      heldAway,
      heldAwayAccountModal,
      portfolioModal,
      portfolioModal: { show: showPortfolioModal, mode: accountEditMode },
      sortColumn,
      wizardCSV
    } = this.state;

    const prismOverall =
      investor.aggregated_prism_scores && investor.aggregated_prism_scores.overall
        ? investor.aggregated_prism_scores.overall.toFixed(1)
        : null;

    if (!this.state.investorLoaded) return <SpinnerLoader spinnerLoading />;

    return (
      <div className="investor-accounts">
        <div className="investor-wrapper">
          <div className="investor-details-header heading">
            {!_.isEmpty(investor) && <InvestorDetailsHeader investor={investor} />}
          </div>
          <div className="investor-details-view">
            <InvestorDetailsView
              whichModal={this.whichModal}
              isProspect={investor.is_prospect}
              investor={investor}
              sortBy={this.sortBy}
              sortColumn={sortColumn}
              pagination={null}
              routerActions={routerActions}
              accounts={accounts}
              toggleEditAccountModal={this.toggleEditAccountModal}
              accountsWithoutGoal={accountsWithoutGoal}
              historical={historical}
              ordering={ordering}
              from="investor accounts"
              prismOverall={prismOverall}
              currentUser={currentUser}
              addAccount={this.whichModal}
              getPrismScore={this.getPrismScore}
              onQuestionSave={this.onQuestionSave}
              excludeAccount={this.excludeAccount}
              showModal={this.showModal}
            >
              {children}
            </InvestorDetailsView>
          </div>
        </div>

        <Modal
          id="basic-profile-modal"
          key={3}
          title="test"
          className="modal-lg modal-basic-profile"
          show={this.state.basicProfileModal}
          onShown={this.showModal}
          onHidden={this.closeModal}
        >
          <ModalHeader title={_.isEmpty(investor) ? '' : `${investor.full_name}'s Profile`} />
          <ModalBody>
            <InvestorProfileBasic
              investor={investor}
              investorProvider={provider}
              closeModal={this.closeModal}
            />
          </ModalBody>
        </Modal>

        <CreateAccountModal
          fileId="model-csv-1"
          {...createAccountModal}
          investor={investor}
          onHide={this.toggleCreateAccountModal}
          showManualModal={this.showPortfolioModal}
          routerActions={routerActions}
          uploadCSVOption={(e, investor) => this.uploadCSVOption(e, investor)}
          heldAway={heldAway}
        />

        <HeldAwayAccountsModal
          fileId="held-away"
          {...heldAwayAccountModal}
          investor={investor}
          onHide={this.toggleHeldAwayAccountModal}
          advisor={currentUser}
          onSubmit={this.heldAwaySubmit}
        />

        <Modal
          id="modelEditModal"
          className="modal-lg"
          show={showPortfolioModal}
          onHidden={() => this.showPortfolioModal(false)}
          ref={c => {
            this.editModal = c;
          }}
        >
          <ModalBody>
            <AccountEdit
              {...portfolioModal}
              marketStore={marketStore}
              registerCSVLoadedCallback={this.registerCSVLoadedCallback}
              onCSVInputChange={this.onCSVInputChange}
              mode={accountEditMode}
              show={showPortfolioModal}
              positions={positions}
              investor={investor}
              hideEditModel={() => this.showPortfolioModal(false)}
              onSuccess={this.fetchInvestorData}
              wizardCSV={wizardCSV}
              initialValues={{ held_away: heldAway }}
              reCalculatePrism={this.getPrismScore}
            />
          </ModalBody>
        </Modal>

        <Disclosure />
      </div>
    );
  }
}

InvestorsAccount.childContextTypes = {
  addAccount: PropTypes.func.isRequired,
  toggleEditAccountModal: PropTypes.func.isRequired
};

InvestorsAccount.contextTypes = {
  advisorProvider: PropTypes.object.isRequired,
  accountProvider: PropTypes.object.isRequired,
  actionProvider: PropTypes.object.isRequired,
  investorGoalsProvider: PropTypes.object.isRequired,
  investorProvider: PropTypes.object.isRequired,
  marketProvider: PropTypes.object.isRequired,
  modelProvider: PropTypes.object.isRequired,
  portfolioProvider: PropTypes.object.isRequired,
  prospectProvider: PropTypes.object.isRequired,
  setupTargetScoreWizard: PropTypes.func.isRequired
};

InvestorsAccount.defaultProps = {
  investor: {},
  ordering: '-value'
};

function mergeProps(stateProps, dispatchProps, ownProps) {
  const {
    location: { pathname }
  } = ownProps;

  const { investorProvider, prospectProvider } = dispatchProps;
  const {
    prospectAccounts,
    prospectAccountsMeta,
    investorAccounts,
    investorAccountsMeta,
    investor,
    prospect
  } = stateProps;
  const isProspect = _.includes(pathname, 'advisor/prospects/');
  const provider = isProspect ? prospectProvider : investorProvider;
  const accounts = isProspect ? prospectAccounts : investorAccounts;
  const accountsMeta = isProspect ? prospectAccountsMeta : investorAccountsMeta;
  return {
    ...stateProps,
    ...ownProps,
    ...dispatchProps,
    provider,
    accounts,
    investorAccountsMeta: accountsMeta,
    investor: isProspect ? prospect : investor
  };
}

export default connect(
  state => ({
    historical: state.accounts.historical,
    households: state.households.list,
    investor: state.investors.view,
    prospect: state.prospects.view,
    investorAccounts: state.investors.viewAccounts || [],
    investorAccountsMeta: state.investors.viewAccountsMeta || {},
    prospectAccounts: state.prospects.viewAccounts || [],
    prospectAccountsMeta: state.prospects.viewAccountsMeta || {},
    marketStore: state.market,
    user: state.auth.user
  }),
  dispatch => ({
    investorProvider: new InvestorProvider({ dispatch }),
    prospectProvider: new ProspectProvider({ dispatch }),
    routerActions: bindActionCreators(routerActions, dispatch)
  }),
  mergeProps
)(InvestorsAccount);
