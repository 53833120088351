/* eslint-disable no-return-assign, react/no-unstable-nested-components */
import cn from 'classnames';
import FeatureLimitReachedModal from 'components/advisor/feature-limit';
import HeldAwayAccountsModal from 'components/advisor/investors/held-away-account-modal/index';
import MoveFromAdvisor from 'components/advisor/move-from-advisor';
import { DEFINING_FIELDS_STEP } from 'components/utils/csv-wizard/constants';
import MergeModal from 'containers/advisor/merge-modal/merge-modal';
import DynamicTable from 'containers/table/dynamic';
import DynamicTableActionButton from 'containers/table/dynamic/action-button';
import AccountDriftLevelFilter from 'containers/table/dynamic/filters/account/drift-level';
import AccountDriftOverallFilter from 'containers/table/dynamic/filters/account/drift-overall';
import AccountPrismOverallFilter from 'containers/table/dynamic/filters/account/prism-overall';
import AccountRiskLevelFilter from 'containers/table/dynamic/filters/account/risk-level';
import AccountTotalFilter from 'containers/table/dynamic/filters/account/total';
import PrismFactorFilter from 'containers/table/dynamic/filters/common/prism-factor';
import {
  getPrismFactorAggregatedSortingId,
  getPrismFactorName,
  OVERALL_FACTOR_ATTR
} from 'containers/table/dynamic/filters/common/prism-factor/utils';
import { PRISM_FACTOR_ATTR } from 'containers/table/dynamic/filters/constants';
import InvestorDriftLevelFilter from 'containers/table/dynamic/filters/investor/drift-level';
import InvestorDriftOverallFilter from 'containers/table/dynamic/filters/investor/drift-overall';
import InvestorPrismOverallFilter from 'containers/table/dynamic/filters/investor/prism-overall';
import InvestorRiskLevelFilter from 'containers/table/dynamic/filters/investor/risk-level';
import InvestorRiskToleranceUpdatedFilter from 'containers/table/dynamic/filters/investor/risk-tolerance-updated';
import InvestorTotalFilter from 'containers/table/dynamic/filters/investor/total';
import Papa from 'papaparse';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { accountsWithPrismScoresInProgressSelector } from 'selectors/accounts';
import { CSVModelMixin } from 'utils/mixins';
import { LOAD_CSV_CONFIG, normalizeCsv } from 'utils/papa_parse_config';
import { triggerAccountPrismScore } from 'utils/prism';
import { getAdvisorIdFromSearchParams, getPortfolioForUpdate } from 'utils/utils';
import { Modal, ModalBody } from '../../../modal';
import CreateAccountModal from '../create/index';
import ModelEdit, { modes } from '../edit/index';
import InvestorAccountsCell from './cell/investor-accounts';
import InvestorDriftCell from './cell/investor-drift';
import InvestorHouseholdCell from './cell/investor-household';
import InvestorNameCell from './cell/investor-name';
import InvestorPortfolioValueCell from './cell/investor-portfolio-value';
import InvestorPrismCell from './cell/investor-prism';
import InvestorRiskToleranceCell from './cell/investor-risk-tolerance';
import InvestorListNotification from './notification';
import InvestorGoalRow from './row/investor-goal';
import './styles.scss';

class InvestorListTable extends PureComponent {
  constructor(props, context) {
    super(props, context);
    CSVModelMixin.applyMixin(this);

    this.state = {
      ...this.state,
      accountEditMode: modes.UPDATE,
      accountForModal: null,
      clientLockedModal: { show: false },
      createAccountModal: { show: false, investor: null, accountDefault: null },
      deleteModal: { type: '', items: [], show: false, submitting: false, label: null },
      enableQuestionnaireModal: true,
      heldAway: false,
      heldAwayAccountModal: { show: false, investor: null },
      hideClientNotification: false,
      investorForModal: null,
      modal: null,
      moveInvestorsModal: { show: false, selectedInvestors: [] },
      showOnlyNewInvestors: props.meta?.params?.only_new || false,
      showExcludedAccounts: props.meta?.params?.excluded || false,
      showModal: false,
      wizardCSV: null,
      mergeModal: { show: false, sourceInvestor: null, targetInvestor: null }
    };
  }

  componentDidUpdate() {
    const { totalNewInvestors } = this.context;
    const { showOnlyNewInvestors } = this.state;
    if (totalNewInvestors === 0 && showOnlyNewInvestors) this.toggleNewInvestors();
  }

  onFetchData = (params = {}) => {
    const { getList, meta } = this.props;
    return getList({ ...(meta?.params || {}), ...params });
  };

  onSelectItems = ids => {
    const { investorProvider, prospectProvider } = this.context;
    const { type } = this.props;
    const provider = type === 'prospect' ? prospectProvider : investorProvider;
    return provider.updateSelectedId(ids);
  };

  refreshList = (params = {}) => {
    this.onSelectItems([]);
    return this.onFetchData(params);
  };

  showConvertToProspect = () => {
    const { user, userProvider } = this.context;
    const { toggleProspectNotSelectedModal } = this.props;
    if (userProvider.isEntry(user)) this.showClientsLocked();
    else toggleProspectNotSelectedModal(this.convertProspect);
  };

  showClientsLocked = () => {
    this.setState({ clientLockedModal: { show: true } });
  };

  hideClientsLocked = () => {
    this.setState({ clientLockedModal: { show: false } });
  };

  toggleExcludedAccounts = () => {
    this.setState(prevState => ({
      ...prevState,
      showExcludedAccounts: !prevState.showExcludedAccounts
    }));
  };

  toggleExcludedAccountsHandler = () => {
    this.toggleExcludedAccounts();
  };

  toggleNewInvestors = () => {
    this.setState(prevState => ({
      ...prevState,
      showOnlyNewInvestors: !prevState.showOnlyNewInvestors
    }));
  };

  toggleNewInvestorsHandler = () => {
    this.toggleNewInvestors();
  };

  showCreateAccountModal = (investor, accountDefault = null) => {
    this.setState({ createAccountModal: { show: true, investor, accountDefault } });
  };

  hideCreateAccountModal = () => {
    this.setState({ createAccountModal: { show: false }, wizardCSV: null });
  };

  showHeldAwayAccountModal = investor => {
    this.setState({ heldAwayAccountModal: { show: true, investor } });
  };

  hideHeldAwayAccountModal = () => {
    this.setState({ heldAwayAccountModal: { show: false } });
  };

  heldAwaySubmit = heldAway => {
    const {
      heldAwayAccountModal: { investor }
    } = this.state;
    this.setState({
      heldAwayAccountModal: { show: false },
      createAccountModal: { show: true, investor },
      heldAway
    });
  };

  showModal = (investor, account, mode = modes.UPDATE) => {
    const { prospectProvider, investorProvider } = this.context;
    const provider = investor.is_prospect ? prospectProvider : investorProvider;
    provider.getAccounts(investor.id).then(res => {
      const accounts = res.data;
      const tempAccount = accounts.find(a => a.id === account.id);
      this.setState({
        showModal: true,
        investorForModal: investor,
        accountForModal: account,
        modal: getPortfolioForUpdate(tempAccount),
        accountEditMode: mode
      });
    });
  };

  uploadCSVOption = (event, investor) => {
    const {
      createAccountModal: { accountDefault }
    } = this.state;
    this.hideCreateAccountModal();
    this.registerCSVLoadedCallback(this.uploadCSVCallback);
    this.onCSVInputChange(event);
    if (accountDefault) return this.showModal(investor, accountDefault, modes.UPDATE);
    return this.showModal(investor, {}, modes.CREATE);
  };

  uploadCSVCallback = event => {
    const { errorsProvider } = this.context;
    const result = normalizeCsv(event.target.result);
    const { data, errors } = Papa.parse(result, LOAD_CSV_CONFIG);
    if (data.length)
      this.setState({
        wizardCSV: {
          data,
          headerRows: Object.keys(data[0]),
          show: true,
          step: DEFINING_FIELDS_STEP
        }
      });
    if (errors && errors.length)
      errors.forEach(event => errorsProvider.registerError(event.message));
  };

  hideEditModel = () => {
    this.setState({ showModal: false, wizardCSV: null });
    this.editModal.hide();
  };

  onSelectRiskToleranceHandler = investor => {
    const { showTargetScoreWizard } = this.context;
    showTargetScoreWizard({
      investors: [investor],
      targetAccountIds: null,
      refresh: this.refreshList
    });
  };

  onAddAccountHandler = investor => () => {
    if (!investor.is_prospect) this.showHeldAwayAccountModal(investor);
    else this.showCreateAccountModal(investor);
  };

  convertProspect = (submit = false) => {
    const { companyProvider, prospectProvider, selectedProspectIds } = this.context;
    const { toggleProspectCompletedLoader } = this.props;
    if (submit) {
      toggleProspectCompletedLoader();
      prospectProvider.convert({ prospects_ids: selectedProspectIds }).then(data => {
        setTimeout(() => {
          if (!data.error) prospectProvider.list();
          prospectProvider.changeBtnClass('btn-not-selected');
          prospectProvider.updateSelectedId([]);
          companyProvider.getLimits();
          toggleProspectCompletedLoader();
        }, 1000);
      });
    }
  };

  deleteSelectedInvestors = async items => {
    const { companyProvider, investorProvider, prospectProvider } = this.context;
    const { type } = this.props;

    const isProspectType = type === 'prospect';
    const provider = isProspectType ? prospectProvider : investorProvider;

    const promisesArray = [];
    items.forEach(id => {
      promisesArray.push(provider.delete(id));
    });

    return Promise.all(promisesArray).then(() => {
      toast.success(`${isProspectType ? 'Prospect' : 'Client'}(s) removed successfully`);
      companyProvider.getLimits();
      this.refreshList();
    });
  };

  bulkUpdateTolerance = () => {
    const { selectedInvestors, showTargetScoreWizard } = this.context;
    showTargetScoreWizard({
      investors: selectedInvestors,
      targetAccountIds: null,
      refresh: this.refreshList
    });
  };

  moveClients = selectedAdvisor => {
    const {
      moveInvestorsModal: { selectedInvestors }
    } = this.state;
    const { advisorProvider, user } = this.context;

    return advisorProvider
      .moveInvestors(user.advisor.id, selectedInvestors, selectedAdvisor)
      .then(() => {
        this.setState({ moveInvestorsModal: { show: false } });
        this.refreshList();
      });
  };

  toggleMoveAdvisorModal = () => {
    const { selectedInvestors: sourceSelectedInvestors } = this.context;
    const selectedInvestors = sourceSelectedInvestors.map(investor => ({
      id: investor.id,
      name: investor.full_name
    }));
    this.setState(prevState => ({
      moveInvestorsModal: { show: !prevState.moveInvestorsModal.show, selectedInvestors }
    }));
  };

  toggleMoveAdvisorModalHandler = () => {
    this.toggleMoveAdvisorModal();
  };

  getInitialParams = () => {
    const { advisorByManager, authProvider } = this.context;
    const { type, selectedTeam } = this.props;
    const { showOnlyNewInvestors, showExcludedAccounts } = this.state;

    const advisorId = getAdvisorIdFromSearchParams(authProvider);
    const initialParams = {};

    if (advisorByManager !== 0) initialParams.by_advisor = advisorByManager;
    if (advisorId) initialParams.by_advisor = advisorId;
    if (selectedTeam !== 0) initialParams.team = selectedTeam;
    if (type === 'prospect') initialParams.ordering = ['-created_at'];
    else {
      initialParams.excluded = showExcludedAccounts;
      initialParams.only_new = showOnlyNewInvestors;
      initialParams.ordering = ['full_name'];
    }

    return initialParams;
  };

  getPrismScore = (accountId, reCalculate = false) => {
    const { accountProvider } = this.context;
    const updateStorePromise = () => [this.onFetchData()];
    return triggerAccountPrismScore(accountId, accountProvider, updateStorePromise, reCalculate);
  };

  parseMergeInvestors = (investor, isProspect) => ({
    id: investor.id,
    accounts: investor.accounts?.length,
    full_name: investor.full_name,
    household: investor.household?.name ?? '-',
    origin: investor.origin ?? '-',
    is_prospect: isProspect
  });

  onClickOpenMergeModal = () => {
    const { selectedInvestors, selectedProspects } = this.context;
    const isProspect = location.pathname.includes('prospects');

    const dataSource = isProspect ? selectedProspects : selectedInvestors;
    const [sourceInvestor, targetInvestor] = dataSource;

    const parsedSourceInvestor = this.parseMergeInvestors(sourceInvestor, isProspect);
    const parsedTargetInvestor = this.parseMergeInvestors(targetInvestor, isProspect);

    this.setState({
      mergeModal: {
        show: true,
        sourceInvestor: parsedSourceInvestor,
        targetInvestor: parsedTargetInvestor
      }
    });
  };

  onClickHideMergeModal = () => {
    this.setState({ mergeModal: { show: false, sourceInvestor: null, targetInvestor: null } });
  };

  render() {
    const {
      authProvider,
      investors,
      prospects,
      routerActions,
      selectedInvestorIds,
      selectedProspectIds,
      totalExcludedAccounts,
      totalNewInvestors,
      user
    } = this.context;

    const {
      marketStore: {
        securities: { positions }
      },
      marketStore,
      meta,
      type
    } = this.props;

    const isProspectType = type === 'prospect';
    const data = isProspectType ? prospects : investors;

    if (!data) return null;

    const {
      accountEditMode,
      accountForModal,
      clientLockedModal,
      createAccountModal,
      heldAway,
      heldAwayAccountModal,
      investorForModal,
      modal,
      moveInvestorsModal,
      showOnlyNewInvestors,
      showExcludedAccounts,
      showModal,
      wizardCSV,
      mergeModal
    } = this.state;

    const initialParams = this.getInitialParams();
    const externalFilters = isProspectType
      ? {}
      : { excluded: showExcludedAccounts, only_new: showOnlyNewInvestors };
    const selectedItemIds = isProspectType ? selectedProspectIds : selectedInvestorIds;

    const prismFactorAttr = meta?.params?.[PRISM_FACTOR_ATTR] || OVERALL_FACTOR_ATTR;
    const prismFactorName = getPrismFactorName(prismFactorAttr);
    const prismFactorAggregatedSortingId = getPrismFactorAggregatedSortingId(prismFactorAttr);

    const headerSelectionActions = (
      <div className="actions">
        {!!selectedItemIds.length && (
          <DynamicTableActionButton
            disabled={selectedItemIds.length !== 2}
            iconId="merge"
            label={
              selectedItemIds.length === 2
                ? `Merge ${isProspectType ? 'prospects' : 'clients'}`
                : `Select exactly 2 ${isProspectType ? 'prospects' : 'clients'}`
            }
            height={21}
            onClick={this.onClickOpenMergeModal}
          />
        )}
        {isProspectType &&
          authProvider.hasAddClientsPermissions(user) &&
          !!selectedItemIds.length && (
            <button
              className="btn btn-outline-dark btn-small"
              onClick={this.showConvertToProspect}
              type="button"
            >
              Convert to Client
            </button>
          )}

        {!isProspectType && !!selectedItemIds.length && (
          <>
            {authProvider.hasManagerPermissions(user) && (
              <DynamicTableActionButton
                iconId="arrow-right-arrow-left-solid"
                label="Move to another advisor"
                onClick={this.toggleMoveAdvisorModalHandler}
              />
            )}
            <button
              className="btn btn-outline-dark btn-small"
              onClick={this.bulkUpdateTolerance}
              type="button"
            >
              Bulk update risk tolerance
            </button>
          </>
        )}
      </div>
    );

    const columns = [
      {
        accessorFn: row => row.full_name,
        id: 'full_name',
        name: isProspectType ? 'Prospective Client' : 'Client',
        cell: investor => <InvestorNameCell investor={investor} />,
        meta: {
          headerStyle: () => ({ width: 300 })
        }
      },
      {
        accessorFn: row => row.household?.name || 'Individual',
        id: 'household__name',
        name: isProspectType ? 'Prospective Household' : 'Household',
        cell: investor => <InvestorHouseholdCell investor={investor} />,
        meta: { className: () => 'text-center', style: () => ({ width: 105 }) }
      },
      {
        accessorFn: row => row.accounts.length,
        id: 'total_accounts',
        name: 'Accounts',
        cell: investor => <InvestorAccountsCell investor={investor} />,
        meta: { className: () => 'text-center', style: () => ({ width: 95 }) }
      },
      {
        accessorFn: row =>
          Math.round(
            row.accounts
              .filter(account => !account.excluded)
              .reduce((acc, account) => acc + account.value, 0)
          ),
        id: 'portfolio_value',
        name: 'Portfolio Value',
        cell: investor => (
          <InvestorPortfolioValueCell
            investor={investor}
            onAddAccountHandler={this.onAddAccountHandler}
          />
        ),
        meta: { className: () => 'text-center', style: () => ({ width: 130 }) }
      },
      {
        accessorFn: row => row.aggregated_prism_scores?.[prismFactorAttr] ?? null,
        id: prismFactorAggregatedSortingId,
        name: prismFactorName ? `PRISM (${prismFactorName})` : 'PRISM',
        cell: investor => <InvestorPrismCell investor={investor} meta={meta} />,
        meta: { className: () => 'text-center td-prism', style: () => ({ width: 120 }) }
      },
      {
        accessorFn: row => row.aggregated_target_scores?.overall ?? null,
        id: 'aggregated_target_scores__overall',
        name: 'Risk Tolerance',
        cell: investor => (
          <InvestorRiskToleranceCell
            investor={investor}
            onSelectRiskToleranceHandler={this.onSelectRiskToleranceHandler}
          />
        ),
        meta: { className: () => 'text-center', style: () => ({ width: 130 }) }
      },
      {
        accessorFn: row => row.drift_summary?.overall ?? null,
        id: 'drift',
        name: 'Drift',
        cell: investor => <InvestorDriftCell investor={investor} />,
        meta: {
          className: ({ row }) =>
            cn('text-center', 'td-drift', { 'td-drift--red': !row?.original?.is_healthy }),
          style: () => ({ width: 75 })
        }
      }
    ];

    return (
      <div id="investors-list-table-container" className="advisor-investor-list">
        <MoveFromAdvisor
          isOpen={moveInvestorsModal.show}
          onMove={this.moveClients}
          onRequestClose={this.toggleMoveAdvisorModal}
          selectedElement={moveInvestorsModal.selectedInvestors}
        />

        <div id="investors-list-table">
          {!isProspectType && showExcludedAccounts && totalExcludedAccounts && (
            <InvestorListNotification
              message={`${totalExcludedAccounts} accounts excluded from aggregation`}
              onClose={this.toggleExcludedAccounts}
            />
          )}

          {!isProspectType &&
            showOnlyNewInvestors &&
            totalNewInvestors > 0 &&
            !showExcludedAccounts && (
              <InvestorListNotification
                message={`You have ${totalNewInvestors} new client${
                  totalNewInvestors > 1 ? 's' : ''
                }`}
                onClose={this.toggleNewInvestors}
              />
            )}

          {!isProspectType &&
            !showOnlyNewInvestors &&
            totalNewInvestors > 0 &&
            !showExcludedAccounts && (
              <div className="top-notification">
                <div className="notification-toggle" />
                <button
                  type="button"
                  className="btn btn-link"
                  onClick={this.toggleNewInvestorsHandler}
                >
                  {totalNewInvestors} Client(s)
                </button>{' '}
                were newly created.
              </div>
            )}

          <DynamicTable
            columns={columns}
            data={data.map(investor => ({ ...investor, subRows: investor.accounts }))}
            enableExpanding
            enableRowSelection
            externalFilters={externalFilters}
            filterComponents={[
              [InvestorRiskToleranceUpdatedFilter, PrismFactorFilter],
              [
                InvestorTotalFilter,
                InvestorRiskLevelFilter,
                InvestorDriftLevelFilter,
                InvestorPrismOverallFilter,
                InvestorDriftOverallFilter
              ],
              [
                AccountTotalFilter,
                AccountRiskLevelFilter,
                AccountDriftLevelFilter,
                AccountPrismOverallFilter,
                AccountDriftOverallFilter
              ]
            ]}
            headerSelectionActions={headerSelectionActions}
            initialParams={initialParams}
            label={isProspectType ? 'Prospect' : 'Investor'}
            meta={meta}
            onDeleteSelectedItems={this.deleteSelectedInvestors}
            onFetchData={this.onFetchData}
            onSelectItems={this.onSelectItems}
            selectedItemIds={selectedItemIds}
            subComponent={InvestorGoalRow}
            subComponentProps={{
              getPrismScore: this.getPrismScore,
              meta,
              showCreateAccountModal: this.showCreateAccountModal,
              showModal: this.showModal
            }}
          />

          {!isProspectType && !showExcludedAccounts && totalExcludedAccounts > 0 && (
            <div className="bottom-notification">
              <img
                className="account-warning-icon"
                src="/img/icons/warning_grey.svg"
                alt="warning"
              />
              <button
                type="button"
                className="btn btn-link"
                onClick={this.toggleExcludedAccountsHandler}
              >
                {totalExcludedAccounts} Accounts
              </button>{' '}
              are excluded from aggregated portfolio value and PRISM rating.
            </div>
          )}

          <Modal
            id="modelEditModal"
            className="modal-lg"
            show={showModal}
            onHidden={this.hideEditModel}
            ref={c => {
              this.editModal = c;
            }}
          >
            <ModalBody>
              <ModelEdit
                account={accountForModal}
                heldAway={heldAway}
                hideEditModel={this.hideEditModel}
                investor={investorForModal}
                marketStore={marketStore}
                mode={accountEditMode}
                model={modal}
                onCSVInputChange={this.onCSVInputChange}
                onSuccess={this.refreshList}
                positions={positions}
                reCalculatePrism={this.getPrismScore}
                registerCSVLoadedCallback={this.registerCSVLoadedCallback}
                show={showModal}
                wizardCSV={wizardCSV}
              />
            </ModalBody>
          </Modal>

          {createAccountModal && createAccountModal.investor && (
            <CreateAccountModal
              fileId="model-csv-1"
              {...createAccountModal}
              onHide={this.hideCreateAccountModal}
              showManualModal={this.showModal}
              routerActions={routerActions}
              uploadCSVOption={this.uploadCSVOption}
              heldAway={heldAway}
            />
          )}

          {heldAwayAccountModal && heldAwayAccountModal.investor && (
            <HeldAwayAccountsModal
              fileId="held-away"
              {...heldAwayAccountModal}
              investor={heldAwayAccountModal.investor}
              onHide={this.hideHeldAwayAccountModal}
              advisor={user}
              onSubmit={this.heldAwaySubmit}
            />
          )}

          <FeatureLimitReachedModal
            content={{
              title: 'Upgrade your plan',
              text: 'Please upgrade your plan to import your clients to StratiFi platform.'
            }}
            show={clientLockedModal.show}
            onHide={this.hideClientsLocked}
          />

          {mergeModal.show && (
            <MergeModal
              sourceInvestorProp={mergeModal.sourceInvestor}
              targetInvestorProp={mergeModal.targetInvestor}
              onClose={this.onClickHideMergeModal}
            />
          )}
        </div>
      </div>
    );
  }
}

InvestorListTable.contextTypes = {
  accountProvider: PropTypes.object.isRequired,
  actionProvider: PropTypes.object.isRequired,
  advisorByManager: PropTypes.number,
  advisorProvider: PropTypes.object.isRequired,
  authProvider: PropTypes.object.isRequired,
  buttonClass: PropTypes.string.isRequired,
  companyProvider: PropTypes.object.isRequired,
  investorAccounts: PropTypes.array,
  investorGoalsProvider: PropTypes.object.isRequired,
  investorProvider: PropTypes.object.isRequired,
  investors: PropTypes.array.isRequired,
  loading: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  model: PropTypes.object,
  prospectAccounts: PropTypes.array,
  prospectProvider: PropTypes.object.isRequired,
  prospects: PropTypes.array.isRequired,
  routerActions: PropTypes.object.isRequired,
  selectedInvestorIds: PropTypes.array.isRequired,
  selectedInvestors: PropTypes.array.isRequired,
  selectedProspectIds: PropTypes.array.isRequired,
  selectedProspects: PropTypes.array.isRequired,
  showTargetScoreWizard: PropTypes.func.isRequired,
  totalExcludedAccounts: PropTypes.number.isRequired,
  totalNewInvestors: PropTypes.number.isRequired,
  user: PropTypes.object.isRequired,
  userProvider: PropTypes.object.isRequired
};

InvestorListTable.propTypes = {
  getList: PropTypes.func.isRequired,
  marketStore: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  prismScoresInProgress: PropTypes.array.isRequired,
  selectedTeam: PropTypes.number.isRequired,
  toggleProspectCompletedLoader: PropTypes.func.isRequired,
  toggleProspectNotSelectedModal: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired
};

export default connect(state => ({
  marketStore: state.market,
  prismScoresInProgress: accountsWithPrismScoresInProgressSelector(state),
  selectedTeam: state.teams.selected.value
}))(InvestorListTable);
